import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ExcelFileNames, TableService } from '../table-service/table.service';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { TableGroup } from '../table-service/model/table.interface';

@Component({
  selector: 'app-statistic-q32024',
  templateUrl: './statistic-q32024.component.html',
  styleUrls: ['./statistic-q32024.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatisticQ32024Component implements OnInit {
  readonly tableData$ = new BehaviorSubject<TableGroup[]>([]);

  constructor(private tableService: TableService) { }

  ngOnInit(): void {
    this.tableService.getTableData(ExcelFileNames.Q3_2024)
      .pipe(take(1))
      .subscribe((data) => {
        this.tableData$.next(data);
      });
  }
}
