import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {CountryService} from '../services/countryService.service';

@Injectable()
export class HelperService {
  constructor(private countryService: CountryService) {
    // TODO: should be added
  }

  getCountryCode(countryName) {
    const findCountry = this.countryService.getCountryList().find(country => {
      return country.name === countryName;
    });
    return findCountry.code;
  }

  getCountryNameByCode(code: string) {
    return this.countryService.getCountryList().find((c) => {
      return c.code === code;
    }).name;
  }

}
