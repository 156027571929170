<app-header></app-header>

<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
          <tr>
            <th>Date</th>
            <th>Channel</th>
            <th>Uptime, <br /> %</th>
            <th>Downtime, <br /> %</th>
            <th>AIS <br /> performance, <br /> ms</th>
            <th>PIS <br /> performance, <br /> ms</th>
            <th>CoF <br /> performance, <br /> ms</th>
            <th>Error response <br /> rate, <br /> %</th>
          </tr>
          </thead>

          <tbody>
          <ng-container *ngFor="let group of tableData$ | async">
            <tr *ngFor="let row of group.rows; let i = index">
              <td *ngIf="i === 0" rowspan="3" class="text-nowrap">{{ group.date | date: 'dd.MM.yyyy'}}</td>
              <td>{{ row.serviceName }}</td>
              <td>{{ row.availability }}</td>
              <td>{{ row.downtime }}</td>
              <td>{{ row.totalRequests }}</td>
              <td>{{ row.successfulRequests }}</td>
              <td>{{ row.errorRequests }}</td>
              <td>{{ row.errorRate }}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>

<app-footer></app-footer>

