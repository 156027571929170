<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h1 class="page-title">Open Banking
        </h1>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>Empower your solutions with banking</h4>
          <p>
            Payment Services Directive (PSD 2) is a Directive of the European Parliament of 25 November 2015 on payment services within the internal market.<br>
            In accordance with the requirements of PSD 2, Papaya Ltd. offers the <a routerLink="/open-banking-documentation">API (Application Programming Interface)</a> Sandbox solution for Open Banking.<br>
            The Sandbox is a safe environment in which Third Party Providers can test ideas and innovations to build the next generation of digital services and products in the field of financial services.<br>
            The solution provided by Papaya Ltd. allows both Account Information Service Providers and Payment Initiation Service Providers to enter the market. It is suitable for both large companies and independent developers.
          </p>
          <p class="mb-3">
            This API intends to provide an interface between:
          </p>
          <ul>
            <li>Account Servicing Payment Service Providers (ASPSP)</li>
            <li>Third Party (Payment Service) Providers (TPP)</li>
          </ul>
          <p class="mb-3">TPP may act with different roles as described below:</p>
          <ul>
            <li>Account Information Service Providers (AISP) (available in Production and SandBox)</li>
            <li>Payment Initiation Service Providers (PISP) (available in Production and SandBox)</li>
            <li>Payment Instrument Issuer Service Providers (PIISP) (available in Production and SandBox)</li>
          </ul>
          <p>
            The Payment Service User (PSU) is the owner of the accounts held by the ASPSP and gives accreditations to the TPP in order to access his accounts information or initiates payment from these accounts<br>
            The API is designed on a REST model using JSON structures.
          </p>
          <p class="mb-3">Before starting the registration process your organisation must meet several prerequisites in order to be successfully onboarded as a TPP for the Open Banking APIs.</p>
          <ul>
            <li>You must have a valid licence to use Open Banking API issued by NCA in any EU countries.</li>
            <li>You must obtain a valid PSD2 compliant client certificate to authenticate your application to the PSD2 APIs and play with production data.</li>
          </ul>
        </section>
      </div>
    </article>
    <article class="section section_page section_col-12">
      <div class="section__header">
        <h2 class="page-title">Open Banking Documentation
        </h2>
      </div>
      <div class="section__content-left">
        <section class="section__row">
          <h4>Welcome to Papaya API documentation</h4>
          <p>
            <strong>Welcome</strong> to Papaya’s API Sandbox, a place for Third Party Payment Service Providers (TPP) to try and test our PSD2 Open APIs. Here you will find necessary documentation, instructions and code samples for making API calls. The implemented services fall under core services and are not covering all the requirements at this time. Papaya is following the Berlin Group API specification and where it is needed extensions are made to cover the full set of requirements needed for the service. The sandbox offers a safe and simple way to test the Financial Institution’s APIs. Developers can get to know themselves with existing and upcoming functionality.
          </p>
          <p>We implemented NextGenPSD2 API based on the JSON data format for payment initiation requests and account information services. All APIs consumes JSON so requests sent to it should have the request body in JSON format.</p>
        </section>
      </div>
      <div class="section__content-right">
        <ul class="list-styled">
          <li><span class="align-middle">* </span> <a routerLink="/openbanking-terms-and-conditions">Terms and Conditions</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/getting-started-guide">Getting Started Guide</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/eidas-certificate">eIDAS certificate</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/production-environment">Production environment</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/general-information-about-the-sandbox">General Information about the Sandbox</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/statistics">XS2A API statistic Q4 2020 - Q42023</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/statistic-q12024">XS2A API statistic Q1 2024</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/statistic-q22024">XS2A API statistic Q2 2024</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/statistic-q32024">XS2A API statistic Q3 2024</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/availability">Availability</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/sca-strong-customer-authentication-methods">SCA (strong customer authentication)</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/security">Security</a></li>
          <li><span class="align-middle">* </span> <a routerLink="/about-api">About API</a></li>
        </ul>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>
