<app-header></app-header>
<main>
  <section class="complaints">
    <div class="container">
      <h1 class="page-title">Complaints</h1>
      <br />
      <form [formGroup]="mailForm" class="complaints__form">
        <h2 class="complaints__title h3">Client information</h2>
        <div class="complaints__inputs form-group">
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('name')"
          >
            <input
              formControlName="name"
              class="input-control"
              type="text"
              name="name"
              placeholder="Your name"
            />
            <span class="label-error" *ngIf="this.isError('name')"
              >Required</span
            >
          </div>
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('email')"
          >
            <input
              formControlName="email"
              class="input-control"
              type="email"
              name="email"
              placeholder="Your email"
            />
            <span class="label-error" *ngIf="this.isError('email')">
              <span *ngIf="this.mailForm.get('email').errors.required"
                >Required</span
              >
              <span *ngIf="this.mailForm.get('email').errors.pattern"
                >Incorrect Email format</span
              >
            </span>
          </div>
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('mobilePhoneNumber')"
          >
            <input
              formControlName="mobilePhoneNumber"
              class="input-control"
              type="type"
              name="mobilePhoneNumber"
              (input)="onInputChange($event)"
              placeholder="Mobile phone number"
            />
            <span class="label-error" *ngIf="this.isError('mobilePhoneNumber')"
              >Required, max length 50</span
            >
          </div>
        </div>
        <h2 class="complaints__title h3">Details</h2>
        <div class="complaints__inputs form-group">
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('complaintSubject')"
          >
            <input
              formControlName="complaintSubject"
              class="input-control"
              type="text"
              name="complaintSubject"
              placeholder="Complaint subject"
            />
            <span class="label-error" *ngIf="this.isError('complaintSubject')"
              >Required, max length 100</span
            >
          </div>

          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('complaintSummary')"
          >
            <textarea
              formControlName="complaintSummary"
              class="input-control"
              name="complaintSummary"
              rows="4"
              placeholder="Complaint summary"
            ></textarea>
            <span class="label-error" *ngIf="this.isError('complaintSummary')"
              >Required, max length 200</span
            >
          </div>
          <div class="complaints__control-wrapper">
            <div class="complaints__group-flex">
              <input
                formControlName="relevantDateOfComplaintEvent"
                class="input-control"
                min="1970-01-01"
                max="2100-01-01"
                type="date"
                name="relevantDateOfComplaintEvent"
                placeholder="Relevant date of complaint event"
                id="relevantDateOfComplaintEvent-input"
                #dateInput
              />
              <label
                for="relevantDateOfComplaintEvent-input"
                class="input-control"
                (click)="onSelectDate()"
              >
                <span
                  *ngIf="
                    !this.mailForm.get('relevantDateOfComplaintEvent').value
                  "
                  >Date</span
                >
                <span
                  *ngIf="
                    this.mailForm.get('relevantDateOfComplaintEvent').value
                  "
                  >{{ this.getDate() }}</span
                >
              </label>

              <button
                style="min-width: 200px"
                class="button button_orange-fill"
                type="button"
                (click)="onSelectDate()"
              >
                Select a date
              </button>
            </div>
          </div>
          <div class="complaints__control-wrapper">
            <div class="complaints__group-flex">
              <input
                #filesInput
                style="display: none"
                id="attachments-input"
                formControlName="attachments"
                class="input-control complaints__control-input-number"
                type="file"
                multiple
                accept=".pdf,.jpg,.png"
                name="attachments"
                placeholder="attachments"
                (change)="onAttachmentsChange($event)"
              />

              <div class="complaints__file-control-wrapper">
                <div
                  *ngFor="let file of this.files"
                  class="complaints__file-control"
                >
                  <span>
                    {{ file.name }}
                  </span>
                  <button
                    style="width: 100px; flex: 0 0 auto"
                    class="button button_orange-fill"
                    type="button"
                    (click)="onRemoveFile(file)"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <span *ngIf="!this.files?.length">No files selected</span>

              <button
                style="min-width: 200px"
                class="button button_orange-fill"
                type="button"
                (click)="onSelectFiles()"
              >
                Upload files
              </button>
            </div>

            <span class="label-error" *ngIf="this.isError('attachments')"
              >Allowed size is 32 Mb.</span
            >
          </div>
          <div
            class="complaints__control-wrapper"
            [class.error]="this.isError('agreement')"
          >
            <div class="complaints__checkbox-wrap">
              <input
                class="complaints__checkbox"
                type="checkbox"
                id="agreement"
                formControlName="agreement"
              />
              <label for="agreement"
                >I agree with the terms of the privacy policy of Papaya Ltd. and
                consent to the processing of my personal data for the purpose of
                considering the complaint.</label
              >
            </div>
            <span class="label-error" *ngIf="this.isError('agreement')"
              >Required</span
            >
          </div>
        </div>

        <div class="complaints__submit">
          <button
            class="button button_orange-fill"
            type="submit"
            (click)="submit($event)"
          >
            Send
          </button>
        </div>
      </form>
    </div>
  </section>

  <div *ngIf="this.isSuccess" class="complaints__pop-up_backdrop">
    <div class="complaints__pop-up">
      <strong class="complaints__pop-up_prompt">
        Thank you for submitting your complaint to Papaya Ltd. We have
        successfully received your submission and are currently reviewing the
        details.
      </strong>
      <div class="complaints__submit">
        <button
          class="button button_orange-fill"
          type="button"
          (click)="complete()"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</main>
<app-footer></app-footer>
