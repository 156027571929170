import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { CareersComponent } from './careers/careers.component';
import { FaqComponent } from './faq/faq.component';
import { OnlineBankingComponent } from './online-banking/online-banking.component';
import { OpenBankingDocumentationComponent } from './open-banking-documentation/open-banking-documentation.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FraudDisclaimerComponent } from './fraud-disclaimer/fraud-disclaimer.component';
import { CustomerSecurityComponent } from './customer-security/customer-security.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { OpenbankingTermsAndConditionsComponent } from './openbanking-terms-and-conditions/openbanking-terms-and-conditions.component';
import { GettingStartedGuideComponent } from './getting-started-guide/getting-started-guide.component';
import { EidasCertificateComponent } from './eidas-certificate/eidas-certificate.component';
import { ProductionEnvironmentComponent } from './production-environment/production-environment.component';
// tslint:disable-next-line:max-line-length
import { GeneralInformationAboutTheSandboxComponent } from './general-information-about-the-sandbox/general-information-about-the-sandbox.component';
import { AvailabilityComponent } from './availability/availability.component';
// tslint:disable-next-line:max-line-length
import { ScaStrongCustomerAuthenticationMethodsComponent } from './sca-strong-customer-authentication-methods/sca-strong-customer-authentication-methods.component';
import { SecurityComponent } from './security/security.component';
import { AboutApiComponent } from './about-api/about-api.component';
import { TtpComponent } from './ttp/ttp.component';
import { SuccessComponent } from './success/success.component';
import { CurrencyRatesComponent } from './currency-rates/currency-rates.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { StatisticQ12024Component } from './statistic-q12024/statistic-q12024.component';
import { StatisticQ22024Component } from './statistic-q22024/statistic-q22024.component';
import { StatisticQ32024Component } from './statistic-q32024/statistic-q32024.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'complaints', component: ComplaintsComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'online-banking', component: OnlineBankingComponent },
  { path: 'online-banking', component: OnlineBankingComponent },
  {
    path: 'open-banking-documentation',
    component: OpenBankingDocumentationComponent,
  },
  { path: 'cookie-policy', component: CookiePolicyComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'fraud-disclaimer', component: FraudDisclaimerComponent },
  { path: 'customer-security', component: CustomerSecurityComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  {
    path: 'openbanking-terms-and-conditions',
    component: OpenbankingTermsAndConditionsComponent,
  },
  { path: 'getting-started-guide', component: GettingStartedGuideComponent },
  { path: 'eidas-certificate', component: EidasCertificateComponent },
  { path: 'production-environment', component: ProductionEnvironmentComponent },
  {
    path: 'general-information-about-the-sandbox',
    component: GeneralInformationAboutTheSandboxComponent,
  },
  { path: 'availability', component: AvailabilityComponent },
  {
    path: 'sca-strong-customer-authentication-methods',
    component: ScaStrongCustomerAuthenticationMethodsComponent,
  },
  { path: 'security', component: SecurityComponent },
  { path: 'about-api', component: AboutApiComponent },
  { path: 'ttp', component: TtpComponent },
  { path: 'success', component: SuccessComponent },
  { path: 'currency-exchanges', component: CurrencyRatesComponent },
  { path: 'statistics', component: StatisticsComponent },
  { path: 'statistic-q12024', component: StatisticQ12024Component },
  { path: 'statistic-q22024', component: StatisticQ22024Component },
  { path: 'statistic-q32024', component: StatisticQ32024Component },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
