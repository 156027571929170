import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TableGroup, TableRow } from './model/table.interface';

export enum ExcelFileNames {
  Q1_2024 = 'XS2A_API_statistic_Q1_2024',
  Q2_2024 = 'XS2A_API_statistic_Q2_2024',
  Q3_2024 = 'XS2A_API_statistic_Q3_2024',
}

@Injectable({
  providedIn: 'root',
})
export class TableService {
  constructor(private http: HttpClient) {}

  getTableData(excelFileName: ExcelFileNames): Observable<TableGroup[]> {
    return this.http
      .get(`../../assets/xlsx/${excelFileName}.xlsx`, { responseType: 'arraybuffer' })
      .pipe(map(data => this.processExcelFile(data)));
  }

  private processExcelFile(data: ArrayBuffer): TableGroup[] {
    const workbook: XLSX.WorkBook = XLSX.read(new Uint8Array(data), { type: 'array' });
    const firstSheet: string = workbook.SheetNames[0];
    const worksheet: XLSX.WorkSheet = workbook.Sheets[firstSheet];

    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    return this.groupAndFixTableData(jsonData);
  }

  private groupAndFixTableData(data: any): TableGroup[] {
    const body = data.slice(1); // Убираем заголовок таблицы
    const groupedData: { [key: string]: TableRow[] } = {};
    const expectedRowCount = 3;
    let currentDate: number | null = null;

    body.forEach(row => {
      const date = row[0];
      if (date) {
        currentDate = date;
        if (!groupedData[currentDate]) {
          groupedData[currentDate] = [];
        }
      }
      if (currentDate) {
        groupedData[currentDate].push(this.parseRow(row.slice(1)));
      }
    });

    const fixedData: TableGroup[] = Object.entries(groupedData).map(([date, rows]) => ({
      date: this.convertExcelDateToJSDate(+date).toISOString(),
      rows,
    }));

    for (let i = 0; i < fixedData.length; i++) {
      const currentGroup = fixedData[i];

      while (currentGroup.rows.length > expectedRowCount) {
        if (fixedData[i + 1]) {
          const excessRow = currentGroup.rows.pop();
          if (excessRow) {
            fixedData[i + 1].rows.unshift(excessRow);
          }
        } else {
          break;
        }
      }

      while (currentGroup.rows.length < expectedRowCount) {
        if (i > 0 && fixedData[i - 1]) {
          const previousGroup = fixedData[i - 1];
          if (previousGroup.rows.length > expectedRowCount) {
            const missingRow = previousGroup.rows.pop();
            if (missingRow) {
              currentGroup.rows.unshift(missingRow);
            }
          }
        } else {
          break;
        }
      }
    }

    return fixedData;
  }

  private convertExcelDateToJSDate(excelDate: number): Date {
    const excelEpochShift = 25569;
    return new Date((excelDate - excelEpochShift) * 86400000);
  }

  private parseRow(row: any[]): TableRow {
    return {
      serviceName: row[0],
      availability: this.formatPercent(row[1]),
      downtime: this.formatPercent(row[2]),
      totalRequests: this.parseNumber(row[3]),
      successfulRequests: this.parseNumber(row[4]),
      errorRequests: this.parseNumber(row[5]),
      errorRate: this.formatPercent(row[6]),
    };
  }

  private formatPercent(value: any): string {
    if (value === 1) {
      return '100,00%';
    }
    if (typeof value === 'number' && value < 1) {
      return `${(value * 100).toFixed(2).replace('.', ',')}%`;
    }
    return value?.toString() || 'N/A';
  }

  private parseNumber(value: any): number | string {
    return typeof value === 'number' ? value : value?.toString() || 'N/A';
  }
}
