<app-header></app-header>
<main class="page-content">
  <div class="container container_page">
    <article class="section section_page section_col-12">
      <div class="table-wrapper">
        <table class="table-statistics table-border table-bordered">
          <thead>
            <tr>
                <th>Date</th>
                <th>Channel</th>
                <th>Uptime, <br /> %</th>
                <th>Downtime, <br /> %</th>
                <th>AIS <br /> performance, <br /> ms</th>
                <th>PIS <br /> performance, <br /> ms</th>
                <th>CoF <br /> performance, <br /> ms</th>
                <th>Error response <br /> rate, <br /> %</th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>99.83%</td>
                <td>0.17%</td>
                <td>61</td>
                <td>106</td>
                <td>64</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>172</td>
                <td>484</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>862</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 02</td>
                <td>Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>101</td>
                <td>58</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>122</td>
                <td>470</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>761</td>
                <td>371</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>49</td>
                <td>78</td>
                <td>49</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>289</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>496</td>
                <td>278</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>76</td>
                <td>46</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>221</td>
                <td>309</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>553</td>
                <td>304</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>63</td>
                <td>97</td>
                <td>64</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>184</td>
                <td>607</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>849</td>
                <td>460</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>108</td>
                <td>54</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>155</td>
                <td>467</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>769</td>
                <td>399</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>105</td>
                <td>50</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>227</td>
                <td>354</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>764</td>
                <td>388</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>104</td>
                <td>59</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1133</td>
                <td>507</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>830</td>
                <td>409</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>65</td>
                <td>114</td>
                <td>62</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.88%</td>
                <td>0.12%</td>
                <td>144</td>
                <td>1063</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>798</td>
                <td>443</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.62%</td>
                <td>1.38%</td>
                <td>57</td>
                <td>97</td>
                <td>57</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>97.59%</td>
                <td>2.41%</td>
                <td>181</td>
                <td>338</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.06%</td>
                <td>1.94%</td>
                <td>576</td>
                <td>335</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>91</td>
                <td>50</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>210</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>540</td>
                <td>308</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>86</td>
                <td>60</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>175</td>
                <td>587</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>863</td>
                <td>421</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>97</td>
                <td>58</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>178</td>
                <td>469</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>96.59%</td>
                <td>3.41%</td>
                <td>795</td>
                <td>387</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>107</td>
                <td>57</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>273</td>
                <td>478</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>793</td>
                <td>400</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>64</td>
                <td>83</td>
                <td>63</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>183</td>
                <td>573</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>858</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>110</td>
                <td>57</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>127</td>
                <td>487</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>797</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>97</td>
                <td>53</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>303</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>516</td>
                <td>293</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>102</td>
                <td>53</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>192</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>571</td>
                <td>319</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>108</td>
                <td>59</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>177</td>
                <td>560</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>863</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>95</td>
                <td>58</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>232</td>
                <td>499</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.22%</td>
                <td>0.78%</td>
                <td>881</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>87</td>
                <td>52</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>171</td>
                <td>725</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>799</td>
                <td>391</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>83</td>
                <td>57</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>170</td>
                <td>442</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>770</td>
                <td>386</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>81</td>
                <td>52</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>93.93%</td>
                <td>6.07%</td>
                <td>162</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>774</td>
                <td>376</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>77</td>
                <td>45</td>
                <td>0.013%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>286</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>504</td>
                <td>285</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>72</td>
                <td>48</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>334</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>519</td>
                <td>291</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.69%</td>
                <td>1.31%</td>
                <td>60</td>
                <td>89</td>
                <td>61</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>98.26%</td>
                <td>1.74%</td>
                <td>162</td>
                <td>511</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.34%</td>
                <td>1.66%</td>
                <td>841</td>
                <td>408</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>97</td>
                <td>53</td>
                <td>0.000%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>117</td>
                <td>415</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>789</td>
                <td>377</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>93</td>
                <td>54</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>403</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>789</td>
                <td>372</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>100</td>
                <td>50</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>397</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>748</td>
                <td>349</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>70</td>
                <td>51</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>702</td>
                <td>337</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 10 31</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>92</td>
                <td>46</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>277</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>488</td>
                <td>259</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>86</td>
                <td>74</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>158</td>
                <td>372</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>401</td>
                <td>297</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 02</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>90</td>
                <td>43</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.21%</td>
                <td>0.79%</td>
                <td>150</td>
                <td>449</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>476</td>
                <td>317</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>105</td>
                <td>54</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>315</td>
                <td>508</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>776</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>96</td>
                <td>56</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>410</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>682</td>
                <td>420</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.62%</td>
                <td>1.38%</td>
                <td>56</td>
                <td>102</td>
                <td>56</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>165</td>
                <td>455</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>681</td>
                <td>431</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>107</td>
                <td>60</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>181</td>
                <td>529</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>662</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>101</td>
                <td>46</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>286</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>374</td>
                <td>289</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>106</td>
                <td>50</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>128</td>
                <td>306</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>397</td>
                <td>300</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>110</td>
                <td>58</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>187</td>
                <td>582</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>748</td>
                <td>462</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>98</td>
                <td>55</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>177</td>
                <td>520</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>702</td>
                <td>453</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>99</td>
                <td>55</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>98.64%</td>
                <td>1.36%</td>
                <td>154</td>
                <td>465</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>694</td>
                <td>427</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>97</td>
                <td>57</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>147</td>
                <td>428</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>687</td>
                <td>423</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>103</td>
                <td>52</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>150</td>
                <td>439</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>717</td>
                <td>437</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>83</td>
                <td>45</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>99.03%</td>
                <td>0.97%</td>
                <td>108</td>
                <td>290</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.15%</td>
                <td>1.85%</td>
                <td>393</td>
                <td>296</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>53</td>
                <td>77</td>
                <td>48</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>140</td>
                <td>338</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>415</td>
                <td>311</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>61</td>
                <td>59</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>170</td>
                <td>521</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>776</td>
                <td>466</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>98</td>
                <td>52</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>385</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>683</td>
                <td>412</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>98</td>
                <td>49</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>130</td>
                <td>362</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>668</td>
                <td>402</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>99</td>
                <td>53</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>137</td>
                <td>390</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>686</td>
                <td>411</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>70</td>
                <td>54</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>411</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>683</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>49</td>
                <td>97</td>
                <td>42</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>94</td>
                <td>248</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>83.34%</td>
                <td>16.66%</td>
                <td>348</td>
                <td>254</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>45</td>
                <td>89</td>
                <td>41</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>345</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>363</td>
                <td>265</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>103</td>
                <td>52</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>455</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>718</td>
                <td>407</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>98.86%</td>
                <td>1.14%</td>
                <td>51</td>
                <td>104</td>
                <td>50</td>
                <td>0.015%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>125</td>
                <td>379</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>98.68%</td>
                <td>1.32%</td>
                <td>658</td>
                <td>382</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>102</td>
                <td>48</td>
                <td>0.010%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>133</td>
                <td>397</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>679</td>
                <td>392</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>103</td>
                <td>49</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>127</td>
                <td>379</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.26%</td>
                <td>0.74%</td>
                <td>642</td>
                <td>375</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>51</td>
                <td>101</td>
                <td>50</td>
                <td>0.006%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>136</td>
                <td>394</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>662</td>
                <td>393</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>48</td>
                <td>82</td>
                <td>42</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>99</td>
                <td>266</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>363</td>
                <td>266</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>46</td>
                <td>83</td>
                <td>41</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>108</td>
                <td>275</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>381</td>
                <td>271</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 11 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>99</td>
                <td>51</td>
                <td>0.002%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>519</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>782</td>
                <td>433</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 01</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>111</td>
                <td>49</td>
                <td>0.020%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>142</td>
                <td>423</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>805</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 02</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>101</td>
                <td>50</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>418</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.94%</td>
                <td>0.06%</td>
                <td>775</td>
                <td>285</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 03</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>113</td>
                <td>51</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>132</td>
                <td>399</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>792</td>
                <td>294</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 04</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>103</td>
                <td>40</td>
                <td>0.001%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>143</td>
                <td>436</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>814</td>
                <td>297</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 05</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>101</td>
                <td>45</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>96</td>
                <td>263</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>604</td>
                <td>225</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 06</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>109</td>
                <td>55</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>112</td>
                <td>289</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>630</td>
                <td>234</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 07</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>111</td>
                <td>52</td>
                <td>0.011%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>166</td>
                <td>513</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>961</td>
                <td>340</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 08</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>113</td>
                <td>54</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>145</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>851</td>
                <td>298</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 09</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>60</td>
                <td>117</td>
                <td>56</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>144</td>
                <td>432</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>853</td>
                <td>299</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 10</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>118</td>
                <td>55</td>
                <td>0.014%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>162</td>
                <td>480</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>910</td>
                <td>313</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 11</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>106</td>
                <td>45</td>
                <td>0.012%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>148</td>
                <td>448</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>843</td>
                <td>305</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 12</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>50</td>
                <td>101</td>
                <td>44</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>100</td>
                <td>273</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>79.17%</td>
                <td>20.83%</td>
                <td>650</td>
                <td>239</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 13</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>119</td>
                <td>52</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>156</td>
                <td>397</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>623</td>
                <td>231</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 14</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>62</td>
                <td>121</td>
                <td>58</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>169</td>
                <td>531</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.72%</td>
                <td>0.28%</td>
                <td>953</td>
                <td>315</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 15</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>109</td>
                <td>51</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>174</td>
                <td>481</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.86%</td>
                <td>0.14%</td>
                <td>961</td>
                <td>328</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 16</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>56</td>
                <td>114</td>
                <td>49</td>
                <td>0.030%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>138</td>
                <td>429</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>837</td>
                <td>297</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 17</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>112</td>
                <td>50</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>98.78%</td>
                <td>1.22%</td>
                <td>138</td>
                <td>416</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.65%</td>
                <td>0.35%</td>
                <td>859</td>
                <td>295</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 18</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>105</td>
                <td>44</td>
                <td>0.007%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>435</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.93%</td>
                <td>0.07%</td>
                <td>877</td>
                <td>305</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 19</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>108</td>
                <td>45</td>
                <td>0.003%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>98</td>
                <td>265</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>623</td>
                <td>231</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 20</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>125</td>
                <td>56</td>
                <td>0.004%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>118</td>
                <td>305</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>99.94%</td>
                <td>0.06%</td>
                <td>666</td>
                <td>240</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 21</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>128</td>
                <td>53</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>517</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>1021</td>
                <td>331</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 22</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>118</td>
                <td>49</td>
                <td>0.008%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>149</td>
                <td>444</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>970</td>
                <td>328</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 23</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>107</td>
                <td>41</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>146</td>
                <td>437</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>871</td>
                <td>306</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 24</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>50</td>
                <td>100</td>
                <td>41</td>
                <td>0.028%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>91</td>
                <td>253</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>643</td>
                <td>228</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 25</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>52</td>
                <td>100</td>
                <td>43</td>
                <td>0.013%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>90</td>
                <td>243</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>629</td>
                <td>228</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 26</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>55</td>
                <td>108</td>
                <td>48</td>
                <td>0.024%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>93</td>
                <td>247</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>671</td>
                <td>233</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 27</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>61</td>
                <td>121</td>
                <td>55</td>
                <td>0.010%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>114</td>
                <td>280</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>709</td>
                <td>253</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 28</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>59</td>
                <td>117</td>
                <td>52</td>
                <td>0.009%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>178</td>
                <td>547</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>960</td>
                <td>334</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 29</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>58</td>
                <td>119</td>
                <td>53</td>
                <td>0.005%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>161</td>
                <td>498</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>885</td>
                <td>315</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 30</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>57</td>
                <td>120</td>
                <td>50</td>
                <td>0.011%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>152</td>
                <td>422</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>880</td>
                <td>316</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td rowspan="3" class="text-nowrap">2020 12 31</td>
                <td class="text-nowrap">Open Banking API</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>54</td>
                <td>105</td>
                <td>35</td>
                <td>0.019%</td>
            </tr>
            <tr>
                <td>Mobile APP</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>142</td>
                <td>266</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
            <tr>
                <td>Internet bank</td>
                <td>100.00%</td>
                <td>0.00%</td>
                <td>690</td>
                <td>256</td>
                <td>N/A</td>
                <td>N/A</td>
            </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 01</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>81</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>677</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 02</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>58</td>
            <td>73</td>
            <td>68</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>633</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 03</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>59</td>
            <td>75</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>453</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>642</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 04</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>52</td>
            <td>67</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>171</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>465</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 05</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>68</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>481</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 06</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>58</td>
            <td>74</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>387</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>700</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 07</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>59</td>
            <td>81</td>
            <td>72</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>535</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 08</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>60</td>
            <td>79</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>456</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>702</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 09</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>80</td>
            <td>61</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.88%</td>
            <td>0.12%</td>
            <td>174</td>
            <td>470</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 10</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>64</td>
            <td>87</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>182</td>
            <td>561</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>927</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 11</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>50</td>
            <td>71</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>489</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 12</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>49</td>
            <td>73</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>157</td>
            <td>361</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>500</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 13</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>63</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>195</td>
            <td>580</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>96.59%</td>
            <td>3.41%</td>
            <td>912</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 14</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>58</td>
            <td>84</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>457</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>758</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 15</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>99.94%</td>
            <td>0.06%</td>
            <td>58</td>
            <td>81</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>530</td>
            <td>830</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>1000</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 16</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>78</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>731</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 17</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>77</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>731</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 18</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>91.17%</td>
            <td>8.83%</td>
            <td>50</td>
            <td>82</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>83.67%</td>
            <td>16.33%</td>
            <td>142</td>
            <td>341</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>83.67%</td>
            <td>16.33%</td>
            <td>484</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 19</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>98.09%</td>
            <td>1.91%</td>
            <td>48</td>
            <td>79</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>94.61%</td>
            <td>5.39%</td>
            <td>149</td>
            <td>349</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>484</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 20</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>60</td>
            <td>91</td>
            <td></td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>546</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>886</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 21</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>87</td>
            <td></td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>434</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>742</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 22</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>84</td>
            <td></td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>177</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>682</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 23</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>93.93%</td>
            <td>6.07%</td>
            <td>163</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>711</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 24</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>92</td>
            <td></td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>175</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.91%</td>
            <td>0.09%</td>
            <td>861</td>
            <td>300</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 25</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>48</td>
            <td>80</td>
            <td></td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>344</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>501</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 26</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>49</td>
            <td>82</td>
            <td></td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 27</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>59</td>
            <td>74</td>
            <td>76</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>168</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>822</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 28</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>82</td>
            <td>71</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>419</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>708</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 29</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>53</td>
            <td>82</td>
            <td>59</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>628</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 30</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>82</td>
            <td>56</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>408</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>626</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 01 31</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>84</td>
            <td></td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>665</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 01</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>81</td>
            <td></td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>137</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>547</td>
            <td>283</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 02</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>63</td>
            <td>82</td>
            <td></td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.21%</td>
            <td>0.79%</td>
            <td>154</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>553</td>
            <td>288</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 03</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>87</td>
            <td>49</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>838</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 04</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>74</td>
            <td>82</td>
            <td>56</td>
            <td>0.057%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>436</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>753</td>
            <td>361</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 05</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>78</td>
            <td></td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>727</td>
            <td>379</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 06</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>79</td>
            <td>82</td>
            <td></td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>436</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>717</td>
            <td>361</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 07</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>81</td>
            <td>79</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>170</td>
            <td>449</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>702</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 08</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>91.67%</td>
            <td>8.33%</td>
            <td>71</td>
            <td>74</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>91.67%</td>
            <td>8.33%</td>
            <td>131</td>
            <td>307</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>91.67%</td>
            <td>8.33%</td>
            <td>496</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 09</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>65</td>
            <td>67</td>
            <td></td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>342</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>536</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 10</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>99.86%</td>
            <td>0.14%</td>
            <td>90</td>
            <td>79</td>
            <td></td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>311</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>869</td>
            <td>446</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 11</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>63</td>
            <td>95</td>
            <td>0.060%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>419</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>746</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 12</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>80</td>
            <td>74</td>
            <td>52</td>
            <td>0.019%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>764</td>
            <td>362</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 13</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>67</td>
            <td>67</td>
            <td>66</td>
            <td>0.028%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>671</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>704</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 14</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>73</td>
            <td></td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>675</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 15</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>55</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>492</td>
            <td>267</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 16</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>56</td>
            <td></td>
            <td>0.027%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>315</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>514</td>
            <td>277</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 17</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>69</td>
            <td>67</td>
            <td>48</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>767</td>
            <td>356</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 18</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>70</td>
            <td>46</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>711</td>
            <td>325</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 19</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>65</td>
            <td>68</td>
            <td>47</td>
            <td>0.021%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>397</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>747</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 20</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>99.58%</td>
            <td>0.42%</td>
            <td>62</td>
            <td>64</td>
            <td>50</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>572</td>
            <td>285</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 21</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>65</td>
            <td>69</td>
            <td>52</td>
            <td>0.063%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>391</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>714</td>
            <td>341</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 22</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>53</td>
            <td>57</td>
            <td>45</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>311</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>272</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 23</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>52</td>
            <td>56</td>
            <td>47</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>522</td>
            <td>278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 24</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>70</td>
            <td>69</td>
            <td>52</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>475</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>782</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 25</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>64</td>
            <td>68</td>
            <td>53</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>722</td>
            <td>336</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 26</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>63</td>
            <td>71</td>
            <td>51</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>736</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 27</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>64</td>
            <td>71</td>
            <td>48</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>733</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 02 28</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>63</td>
            <td>68</td>
            <td>48</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>447</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>720</td>
            <td>335</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 01</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>79</td>
            <td>56</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>602</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>732</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 02</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>76</td>
            <td>55</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>703</td>
            <td>422</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 03</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>68</td>
            <td>68</td>
            <td>55</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>498</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1164</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 04</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>74</td>
            <td>51</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>135</td>
            <td>424</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.94%</td>
            <td>0.06%</td>
            <td>659</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 05</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>48</td>
            <td>59</td>
            <td>52</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>167</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>370</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 06</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>49</td>
            <td>62</td>
            <td>59</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>412</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>382</td>
            <td>300</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 07</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>60</td>
            <td>92</td>
            <td>69</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>1126</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>741</td>
            <td>474</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 08</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>58</td>
            <td>75</td>
            <td>59</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>193</td>
            <td>2886</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.06%</td>
            <td>0.94%</td>
            <td>675</td>
            <td>440</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 09</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>59</td>
            <td>72</td>
            <td>55</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>602</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>666</td>
            <td>434</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 10</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>78</td>
            <td>59</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>505</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.75%</td>
            <td>0.25%</td>
            <td>698</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 11</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>78</td>
            <td>57</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>485</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>679</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 12</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>48</td>
            <td>72</td>
            <td>50</td>
            <td>0.025%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>317</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>362</td>
            <td>298</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 13</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>46</td>
            <td>68</td>
            <td>47</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>377</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>369</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 14</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>57</td>
            <td>60</td>
            <td>55</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>528</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>751</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 15</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>85</td>
            <td>104</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>701</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 16</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>53</td>
            <td>69</td>
            <td>54</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>168</td>
            <td>424</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>656</td>
            <td>416</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 17</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>74</td>
            <td>50</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 18</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>53</td>
            <td>82</td>
            <td>57</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>650</td>
            <td>421</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 19</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>84.69%</td>
            <td>15.31%</td>
            <td>181</td>
            <td>238</td>
            <td>5441</td>
            <td>0.120%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>83.34%</td>
            <td>16.66%</td>
            <td>155</td>
            <td>285</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>83.34%</td>
            <td>16.66%</td>
            <td>348</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 20</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>95.25%</td>
            <td>4.75%</td>
            <td>67</td>
            <td>86</td>
            <td>51</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>393</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 21</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>63</td>
            <td>61</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>687</td>
            <td>444</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 22</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>84</td>
            <td>53</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>642</td>
            <td>413</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 23</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>87</td>
            <td>53</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>157</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>650</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 24</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>82</td>
            <td>55</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>644</td>
            <td>412</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 25</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>91</td>
            <td>55</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>427</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>643</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 26</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>48</td>
            <td>90</td>
            <td>57</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>282</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>346</td>
            <td>287</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 27</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>48</td>
            <td>88</td>
            <td>53</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>322</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>367</td>
            <td>302</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 28</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>58</td>
            <td>95</td>
            <td>56</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.92%</td>
            <td>0.08%</td>
            <td>715</td>
            <td>459</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 29</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>96</td>
            <td>55</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>97.70%</td>
            <td>2.30%</td>
            <td>175</td>
            <td>424</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>660</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 30</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>76</td>
            <td>60</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>710</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 03 31</td>
            <td class="text-nowrap">Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>60</td>
            <td>68</td>
            <td>67</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>579</td>
            <td>314</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 01</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>76</td>
            <td>88</td>
            <td></td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>509</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>688</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 02</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>72</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>453</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>614</td>
            <td>240</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 03</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>89</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>592</td>
            <td>240</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 04</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>102</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>476</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>629</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 05</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>97</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>643</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 06</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>82</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>478</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 07</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>85</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>512</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 08</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>98</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>718</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 09</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>100</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>541</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>649</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 10</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>120</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>598</td>
            <td>790</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>775</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 11</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>70</td>
            <td>107</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>470</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>663</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 12</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>100</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>458</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>674</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 13</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>60</td>
            <td>117</td>
            <td></td>
            <td>0.027%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>79.17%</td>
            <td>20.83%</td>
            <td>147</td>
            <td>376</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>80.65%</td>
            <td>19.35%</td>
            <td>479</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 14</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>65</td>
            <td>138</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>509</td>
            <td>220</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 15</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>71</td>
            <td>117</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>256</td>
            <td>690</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>853</td>
            <td>320</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 16</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>84</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>477</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 17</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>642</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 18</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>89</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>441</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>649</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 19</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>89</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.86%</td>
            <td>0.14%</td>
            <td>717</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 20</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>480</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 21</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>51</td>
            <td>82</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>332</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>506</td>
            <td>220</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 22</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>177</td>
            <td>209</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>943</td>
            <td>1113</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>586</td>
            <td>240</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 23</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>50</td>
            <td>83</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>469</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 24</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>46</td>
            <td>78</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>449</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 25</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>46</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>440</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>652</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 26</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>53</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 27</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>48</td>
            <td>74</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>331</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>476</td>
            <td>200</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 28</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>47</td>
            <td>76</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>490</td>
            <td>210</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 29</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>84</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>187</td>
            <td>567</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>733</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 04 30</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>58</td>
            <td>87</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>585</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>713</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 01</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>71</td>
            <td>103</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>327</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>368</td>
            <td>310</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 02</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>115</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>530</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>530</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 03</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>79</td>
            <td>107</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>447</td>
            <td>530</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 04</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>111</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>443</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 05</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>115</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 06</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>121</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.63%</td>
            <td>0.37%</td>
            <td>231</td>
            <td>545</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.98%</td>
            <td>0.02%</td>
            <td>545</td>
            <td>460</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 07</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>113</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>356</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>372</td>
            <td>320</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 08</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>117</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>811</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>382</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 09</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>116</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>189</td>
            <td>527</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>495</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 10</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>114</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>408</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>559</td>
            <td>470</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 11</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>84</td>
            <td>115</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>182</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>337</td>
            <td>300</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 12</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>108</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>503</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>410</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 13</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>83</td>
            <td>112</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>419</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>405</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 14</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>65</td>
            <td>96</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>336</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>321</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 15</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>98</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>332</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>335</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 16</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>116</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>444</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 17</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>104</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>379</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>381</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 18</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>76</td>
            <td>105</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>405</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>382</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 19</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>114</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>406</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 20</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>114</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>172</td>
            <td>387</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>409</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 21</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>70</td>
            <td>203</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>91.62%</td>
            <td>8.38%</td>
            <td>144</td>
            <td>335</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>91.62%</td>
            <td>8.38%</td>
            <td>356</td>
            <td>310</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 22</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>101</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>325</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>333</td>
            <td>300</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 23</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>121</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>457</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 24</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>76</td>
            <td>110</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>413</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>402</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 25</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>110</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>412</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 26</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>204</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>455</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>409</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 27</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>117</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>417</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 28</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>117</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>351</td>
            <td>300</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 29</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>81</td>
            <td>117</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>372</td>
            <td>320</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 30</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>79</td>
            <td>114</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>480</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 05 31</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>103</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>420</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 01</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>76</td>
            <td>88</td>
            <td></td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>509</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>469</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 02</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>72</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>453</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>418</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 03</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>89</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>422</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 04</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>102</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>476</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>433</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 05</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>97</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>441</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 06</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>82</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>335</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 07</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>66</td>
            <td>85</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>356</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 08</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>98</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>477</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 09</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>100</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>541</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>448</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 10</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>120</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>598</td>
            <td>790</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>500</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 11</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>70</td>
            <td>107</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>470</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>449</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 12</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>100</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>458</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>448</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 13</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>60</td>
            <td>117</td>
            <td></td>
            <td>0.027%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>79.17%</td>
            <td>20.83%</td>
            <td>147</td>
            <td>376</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>79.19%</td>
            <td>20.81%</td>
            <td>345</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 14</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>65</td>
            <td>138</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>366</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 15</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>71</td>
            <td>117</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>256</td>
            <td>690</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>518</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 16</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>56</td>
            <td>84</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>477</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>454</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 17</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>458</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 18</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>89</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>441</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>444</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 19</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>61</td>
            <td>89</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>460</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 20</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>54</td>
            <td>86</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>338</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 21</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>51</td>
            <td>82</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>332</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>359</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 22</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>177</td>
            <td>209</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>95.90%</td>
            <td>4.10%</td>
            <td>943</td>
            <td>1113</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>96.32%</td>
            <td>3.68%</td>
            <td>531</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 23</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>50</td>
            <td>83</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>414</td>
            <td>320</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 24</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>46</td>
            <td>78</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>337</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 25</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>46</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>440</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>456</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 26</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>55</td>
            <td>53</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>434</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 27</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>48</td>
            <td>74</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>331</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>348</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 28</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>47</td>
            <td>76</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>363</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 29</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>57</td>
            <td>84</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>187</td>
            <td>567</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>504</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2021 06 30</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>58</td>
            <td>87</td>
            <td></td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>585</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>506</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/1/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>93</td>
            <td>84</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>453</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>905</td>
            <td>656</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/2/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>85</td>
            <td>79</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>469</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>823</td>
            <td>620</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/3/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>79</td>
            <td>72</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>252</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>527</td>
            <td>507</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/4/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>83</td>
            <td>75</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>292</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>559</td>
            <td>567</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/5/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>90</td>
            <td>82</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>218</td>
            <td>602</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>851</td>
            <td>632</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/6/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>83</td>
            <td>74</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>368</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>596</td>
            <td>559</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/7/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>89</td>
            <td>83</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>186</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>561</td>
            <td>618</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/8/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>87</td>
            <td>81</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>182</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>833</td>
            <td>625</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/9/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>86</td>
            <td>84</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>202</td>
            <td>515</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>832</td>
            <td>645</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/10/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>81</td>
            <td>76</td>
            <td>81</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>554</td>
            <td>550</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/11/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>80</td>
            <td>72</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>326</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>603</td>
            <td>642</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/12/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>90</td>
            <td>87</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>228</td>
            <td>629</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>948</td>
            <td>762</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/13/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>90</td>
            <td>82</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>826</td>
            <td>610</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/14/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>90</td>
            <td>81</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>843</td>
            <td>608</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/15/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>96</td>
            <td>85</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>202</td>
            <td>485</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>865</td>
            <td>622</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/16/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>87</td>
            <td>79</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>175</td>
            <td>438</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>812</td>
            <td>578</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/17/2021</td>
            <td>Open Banking API</td>
            <td>87.50%</td>
            <td>12.50%</td>
            <td>75</td>
            <td>77</td>
            <td>71</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>87.50%</td>
            <td>12.50%</td>
            <td>114</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>87.50%</td>
            <td>12.50%</td>
            <td>489</td>
            <td>397</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/18/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>79</td>
            <td>81</td>
            <td>71</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>434</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>532</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/19/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>91</td>
            <td>95</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>849</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>859</td>
            <td>694</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/20/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>87</td>
            <td>83</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>837</td>
            <td>657</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/21/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>84</td>
            <td>85</td>
            <td>81</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>869</td>
            <td>895</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/22/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>86</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>865</td>
            <td>758</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/23/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>84</td>
            <td>82</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>387</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>860</td>
            <td>747</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/24/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>77</td>
            <td>78</td>
            <td>70</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>246</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>588</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/25/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>80</td>
            <td>70</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>306</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>634</td>
            <td>954</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/26/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>91</td>
            <td>83</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>469</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>856</td>
            <td>671</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/27/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>83</td>
            <td>89</td>
            <td>84</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>398</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>793</td>
            <td>586</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/28/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>86</td>
            <td>81</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>776</td>
            <td>570</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/29/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>89</td>
            <td>80</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>368</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>770</td>
            <td>573</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/30/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>103</td>
            <td>91</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>171</td>
            <td>413</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>805</td>
            <td>604</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/31/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>84</td>
            <td>82</td>
            <td>71</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>254</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>493</td>
            <td>436</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/1/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>87</td>
            <td>72</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>304</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>499</td>
            <td>363</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/2/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>99</td>
            <td>84</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>207</td>
            <td>595</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>764</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/3/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>91</td>
            <td>77</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>399</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>663</td>
            <td>438</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/4/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>88</td>
            <td>87</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>558</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>654</td>
            <td>434</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/5/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>89</td>
            <td>92</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/6/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>90</td>
            <td>95</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>168</td>
            <td>447</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>651</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/7/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>81</td>
            <td>83</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>172</td>
            <td>268</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>463</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/8/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>83</td>
            <td>82</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>167</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>491</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/9/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>96</td>
            <td>114</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>201</td>
            <td>565</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>719</td>
            <td>493</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/10/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>92</td>
            <td>95</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>663</td>
            <td>499</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/11/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>88</td>
            <td>97</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>449</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>656</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/12/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>89</td>
            <td>93</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>157</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>644</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/13/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>89</td>
            <td>93</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>440</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/14/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>84</td>
            <td>83</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>275</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>457</td>
            <td>356</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/15/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>85</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>217</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>491</td>
            <td>396</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/16/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>94</td>
            <td>100</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>217</td>
            <td>593</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>738</td>
            <td>503</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/17/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>92</td>
            <td>97</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/18/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>124</td>
            <td>171</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>459</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>650</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/19/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>95</td>
            <td>88</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>659</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/20/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>92</td>
            <td>87</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>688</td>
            <td>523</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/21/2021</td>
            <td>Open Banking API</td>
            <td>95.84%</td>
            <td>4.16%</td>
            <td>108</td>
            <td>86</td>
            <td>79</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>95.84%</td>
            <td>4.16%</td>
            <td>179</td>
            <td>249</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>95.84%</td>
            <td>4.16%</td>
            <td>439</td>
            <td>326</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/22/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>89</td>
            <td>79</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>475</td>
            <td>363</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/23/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>95</td>
            <td>95</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>718</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/24/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>98</td>
            <td>90</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>645</td>
            <td>422</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/25/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>93</td>
            <td>90</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>643</td>
            <td>418</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/26/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>93</td>
            <td>91</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>644</td>
            <td>424</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/27/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>91</td>
            <td>89</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>191</td>
            <td>384</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>639</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/28/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>85</td>
            <td>78</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>475</td>
            <td>391</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/29/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>87</td>
            <td>78</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>292</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>486</td>
            <td>424</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/30/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>98</td>
            <td>103</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>218</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>712</td>
            <td>460</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/1/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>94</td>
            <td>97</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>472</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>845</td>
            <td>523</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/2/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>96</td>
            <td>93</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>189</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>880</td>
            <td>557</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/3/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>96</td>
            <td>96</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>566</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>852</td>
            <td>646</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/4/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>92</td>
            <td>97</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>311</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>926</td>
            <td>721</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/5/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>80</td>
            <td>75</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>265</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>706</td>
            <td>915</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/6/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>83</td>
            <td>78</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>552</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>804</td>
            <td>1171</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/7/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>95</td>
            <td>101</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>521</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>949</td>
            <td>677</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/8/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>96</td>
            <td>94</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>190</td>
            <td>456</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>821</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/9/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>95</td>
            <td>97</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>190</td>
            <td>463</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>808</td>
            <td>493</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/10/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>98</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>474</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>826</td>
            <td>526</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/11/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>95</td>
            <td>96</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>563</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>901</td>
            <td>614</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/12/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>82</td>
            <td>78</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>271</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>601</td>
            <td>551</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/13/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>89</td>
            <td>81</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>723</td>
            <td>893</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/14/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>96</td>
            <td>103</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>231</td>
            <td>621</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>919</td>
            <td>577</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/15/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>94</td>
            <td>95</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>194</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>826</td>
            <td>502</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/16/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>93</td>
            <td>93</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>504</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>819</td>
            <td>477</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/17/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>154</td>
            <td>93</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>795</td>
            <td>456</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/18/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>231</td>
            <td>92</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>785</td>
            <td>443</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/19/2021</td>
            <td>Open Banking API</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>108</td>
            <td>229</td>
            <td>80</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>121</td>
            <td>277</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>558</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/20/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>239</td>
            <td>84</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>316</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>603</td>
            <td>388</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/21/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>259</td>
            <td>106</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>221</td>
            <td>572</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>877</td>
            <td>510</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/22/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>229</td>
            <td>95</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>789</td>
            <td>470</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/23/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>224</td>
            <td>95</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>415</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>792</td>
            <td>470</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/24/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>226</td>
            <td>94</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>751</td>
            <td>444</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/25/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>231</td>
            <td>94</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>178</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>756</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/26/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>223</td>
            <td>82</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>264</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>540</td>
            <td>367</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/27/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>234</td>
            <td>82</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>272</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>570</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/28/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>249</td>
            <td>103</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>510</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>852</td>
            <td>511</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/29/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>244</td>
            <td>101</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>415</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>753</td>
            <td>447</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/30/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>243</td>
            <td>102</td>
            <td>0.030%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>183</td>
            <td>416</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>766</td>
            <td>441</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/31/2021</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>249</td>
            <td>107</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>209</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>818</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">01.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>287</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>681</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>460</td>
            <td>414</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">02.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>313</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>765</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>526</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">03.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>333</td>
            <td>98</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>230</td>
            <td>1048</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>996</td>
            <td>715</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">04.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>330</td>
            <td>91</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>928</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>825</td>
            <td>644</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">05.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>336</td>
            <td>96</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>992</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>824</td>
            <td>664</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">06.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>319</td>
            <td>91</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>935</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>808</td>
            <td>628</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">07.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>338</td>
            <td>99</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>245</td>
            <td>1091</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>790</td>
            <td>647</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">08.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>298</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>721</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>466</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">09.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>306</td>
            <td>82</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>756</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>495</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>332</td>
            <td>119</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>309</td>
            <td>1156</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1028</td>
            <td>839</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>260</td>
            <td>95</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>213</td>
            <td>746</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>823</td>
            <td>663</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>257</td>
            <td>96</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>777</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>859</td>
            <td>663</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">13.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>264</td>
            <td>112</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>712</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>819</td>
            <td>652</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">14.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>269</td>
            <td>97</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>753</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>810</td>
            <td>684</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">15.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>233</td>
            <td>75</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>456</td>
            <td>411</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">16.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>244</td>
            <td>74</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>531</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>497</td>
            <td>441</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">17.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>293</td>
            <td>108</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>933</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>942</td>
            <td>723</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">18.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>233</td>
            <td>85</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>709</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>818</td>
            <td>631</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">19.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>232</td>
            <td>85</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>698</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>800</td>
            <td>616</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">20.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>248</td>
            <td>92</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>737</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>799</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">21.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>254</td>
            <td>80</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>706</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>792</td>
            <td>632</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">22.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>226</td>
            <td>71</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>484</td>
            <td>453</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">23.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>225</td>
            <td>72</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>521</td>
            <td>505</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">24.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>253</td>
            <td>100</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>868</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>946</td>
            <td>750</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">25.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>219</td>
            <td>82</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>222</td>
            <td>758</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>867</td>
            <td>680</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">26.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>221</td>
            <td>87</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>717</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>821</td>
            <td>633</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">27.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>214</td>
            <td>86</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>207</td>
            <td>700</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>815</td>
            <td>623</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">28.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>228</td>
            <td>91</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>699</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>814</td>
            <td>642</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">29.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>196</td>
            <td>68</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>478</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>464</td>
            <td>427</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">30.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>208</td>
            <td>73</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>543</td>
            <td>519</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">31.01.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>242</td>
            <td>98</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>265</td>
            <td>905</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1008</td>
            <td>775</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">01.02.2022</td>
            <td>Open Banking API</td>
            <td>94.00%</td>
            <td>6.00%</td>
            <td>161</td>
            <td>254</td>
            <td>91</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>247</td>
            <td>858</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>563</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">02.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>207</td>
            <td>85</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>725</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>548</td>
            <td>422</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">03.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>207</td>
            <td>83</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>785</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>549</td>
            <td>419</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">04.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>226</td>
            <td>93</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>228</td>
            <td>751</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>581</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">05.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>194</td>
            <td>69</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>406</td>
            <td>326</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">06.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>201</td>
            <td>71</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>428</td>
            <td>346</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">07.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>242</td>
            <td>96</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>868</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>636</td>
            <td>463</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">08.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>187</td>
            <td>277</td>
            <td>125</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>252</td>
            <td>824</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>590</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">09.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>283</td>
            <td>125</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>328</td>
            <td>918</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>590</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>321</td>
            <td>167</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>292</td>
            <td>944</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>612</td>
            <td>441</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>257</td>
            <td>114</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>834</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>618</td>
            <td>440</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12.02.2022</td>
            <td>Open Banking API</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>117</td>
            <td>203</td>
            <td>89</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>138</td>
            <td>553</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>408</td>
            <td>321</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">13.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>200</td>
            <td>73</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>166</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>428</td>
            <td>339</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">14.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>230</td>
            <td>101</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>252</td>
            <td>823</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>623</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">15.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>226</td>
            <td>100</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>820</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>603</td>
            <td>446</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">16.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>209</td>
            <td>81</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>606</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>572</td>
            <td>443</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">17.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>268</td>
            <td>113</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>221</td>
            <td>772</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>598</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">18.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>215</td>
            <td>92</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>755</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>610</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">19.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>197</td>
            <td>71</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>492</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>427</td>
            <td>351</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">20.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>201</td>
            <td>75</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>516</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>468</td>
            <td>388</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">21.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>230</td>
            <td>109</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>280</td>
            <td>886</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>662</td>
            <td>515</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">22.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>200</td>
            <td>87</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>727</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>565</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">23.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>209</td>
            <td>93</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>708</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>408</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">24.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>189</td>
            <td>82</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>648</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>539</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">25.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>210</td>
            <td>95</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>231</td>
            <td>734</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>592</td>
            <td>458</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">26.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>179</td>
            <td>69</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>414</td>
            <td>377</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">27.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>180</td>
            <td>74</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>452</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">28.02.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>216</td>
            <td>109</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>300</td>
            <td>916</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>628</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">01.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>206</td>
            <td>93</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>245</td>
            <td>787</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>578</td>
            <td>472</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">02.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>198</td>
            <td>94</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>776</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>554</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">03.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>201</td>
            <td>95</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>836</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>514</td>
            <td>401</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">04.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>225</td>
            <td>135</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>797</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>540</td>
            <td>401</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">05.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>186</td>
            <td>82</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>510</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>385</td>
            <td>308</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">06.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>190</td>
            <td>77</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>549</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>418</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">07.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>190</td>
            <td>262</td>
            <td>135</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>338</td>
            <td>1001</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>592</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">08.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>241</td>
            <td>111</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>262</td>
            <td>810</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>388</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">09.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>242</td>
            <td>112</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>286</td>
            <td>856</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>561</td>
            <td>392</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>268</td>
            <td>133</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>346</td>
            <td>965</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>579</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>193</td>
            <td>80</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>624</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>571</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>185</td>
            <td>83</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>372</td>
            <td>289</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">13.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>190</td>
            <td>76</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>398</td>
            <td>302</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">14.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>219</td>
            <td>117</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>297</td>
            <td>918</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>596</td>
            <td>411</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">15.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>192</td>
            <td>124</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>331</td>
            <td>884</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>578</td>
            <td>392</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">16.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>184</td>
            <td>87</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>247</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>548</td>
            <td>377</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">17.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>181</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>676</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>549</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">18.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>183</td>
            <td>79</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>350</td>
            <td>724</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>570</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">19.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>169</td>
            <td>66</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>466</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>389</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">20.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>178</td>
            <td>69</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>406</td>
            <td>305</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">21.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>185</td>
            <td>72</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>346</td>
            <td>841</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>598</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">22.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>180</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>714</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>557</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">23.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>181</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>228</td>
            <td>693</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>537</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">24.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>179</td>
            <td>77</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>663</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>528</td>
            <td>387</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">25.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>180</td>
            <td>77</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>241</td>
            <td>673</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>540</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">26.03.2022</td>
            <td>Open Banking API</td>
            <td>82.00%</td>
            <td>18.00%</td>
            <td>89</td>
            <td>173</td>
            <td>65</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>82.00%</td>
            <td>18.00%</td>
            <td>208</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>82.00%</td>
            <td>18.00%</td>
            <td>375</td>
            <td>298</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">27.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>176</td>
            <td>68</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>388</td>
            <td>316</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">28.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>187</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>288</td>
            <td>817</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>555</td>
            <td>415</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">29.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>180</td>
            <td>74</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>234</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>508</td>
            <td>392</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">30.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>178</td>
            <td>75</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>695</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>516</td>
            <td>384</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">31.03.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>179</td>
            <td>83</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>259</td>
            <td>739</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>398</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">01.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>287</td>
            <td>69</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>681</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>498</td>
            <td>329</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">02.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>313</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>765</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>563</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">03.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>333</td>
            <td>97</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>230</td>
            <td>1048</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>804</td>
            <td>518</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">04.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>330</td>
            <td>91</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>928</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>711</td>
            <td>473</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">05.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>336</td>
            <td>96</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>992</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>720</td>
            <td>515</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">06.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>319</td>
            <td>91</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>935</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>671</td>
            <td>444</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">07.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>338</td>
            <td>99</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>245</td>
            <td>1091</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>698</td>
            <td>482</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">08.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>298</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>721</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>513</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">09.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>306</td>
            <td>76</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>756</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>565</td>
            <td>436</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>332</td>
            <td>119</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>309</td>
            <td>1156</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>920</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>260</td>
            <td>95</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>213</td>
            <td>746</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>709</td>
            <td>450</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>257</td>
            <td>96</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>777</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>703</td>
            <td>434</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">13.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>264</td>
            <td>95</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>712</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>694</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">14.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>269</td>
            <td>97</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>753</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>699</td>
            <td>444</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">15.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>233</td>
            <td>75</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>501</td>
            <td>323</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">16.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>244</td>
            <td>74</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>531</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>534</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">17.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>293</td>
            <td>108</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>933</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>799</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">18.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>233</td>
            <td>85</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>709</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>708</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">19.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>232</td>
            <td>85</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>698</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>695</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">20.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>248</td>
            <td>88</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>737</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>703</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">21.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>254</td>
            <td>80</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>706</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>698</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">22.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>226</td>
            <td>71</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>498</td>
            <td>331</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">23.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>225</td>
            <td>72</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>532</td>
            <td>367</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">24.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>253</td>
            <td>100</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>868</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>786</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">25.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>219</td>
            <td>89</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>222</td>
            <td>758</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>709</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">26.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>221</td>
            <td>87</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>717</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>702</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">27.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>214</td>
            <td>86</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>207</td>
            <td>700</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>692</td>
            <td>421</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">28.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>228</td>
            <td>91</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>699</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>688</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">29.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>196</td>
            <td>70</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>478</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>499</td>
            <td>332</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">30.04.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>208</td>
            <td>73</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>531</td>
            <td>365</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">01.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>242</td>
            <td>98</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>265</td>
            <td>905</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>791</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">02.05.2022</td>
            <td>Open Banking API</td>
            <td>94.00%</td>
            <td>6.00%</td>
            <td>161</td>
            <td>254</td>
            <td>91</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>247</td>
            <td>858</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>770</td>
            <td>478</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">03.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>207</td>
            <td>85</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>725</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>707</td>
            <td>450</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">04.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>207</td>
            <td>83</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>785</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>704</td>
            <td>447</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">05.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>226</td>
            <td>90</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>228</td>
            <td>751</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>681</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">06.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>194</td>
            <td>69</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>498</td>
            <td>344</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">07.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>201</td>
            <td>71</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>546</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">08.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>242</td>
            <td>96</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>868</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>798</td>
            <td>528</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">09.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>187</td>
            <td>277</td>
            <td>125</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>252</td>
            <td>824</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>727</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>283</td>
            <td>125</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>328</td>
            <td>918</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>712</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>321</td>
            <td>167</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>292</td>
            <td>944</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>730</td>
            <td>476</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>257</td>
            <td>114</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>834</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>716</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">13.05.2022</td>
            <td>Open Banking API</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>117</td>
            <td>203</td>
            <td>76</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>138</td>
            <td>553</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>80.00%</td>
            <td>0.00%</td>
            <td>511</td>
            <td>335</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">14.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>200</td>
            <td>73</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>166</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>365</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">15.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>230</td>
            <td>101</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>252</td>
            <td>823</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>775</td>
            <td>498</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">16.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>226</td>
            <td>100</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>820</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>742</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">17.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>209</td>
            <td>81</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>606</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>723</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">18.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>268</td>
            <td>113</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>221</td>
            <td>772</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>749</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">19.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>215</td>
            <td>88</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>755</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>720</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">20.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>197</td>
            <td>71</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>492</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>531</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">21.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>201</td>
            <td>75</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>516</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>585</td>
            <td>421</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">22.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>230</td>
            <td>109</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>280</td>
            <td>886</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>843</td>
            <td>561</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">23.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>200</td>
            <td>87</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>727</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>718</td>
            <td>438</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">24.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>209</td>
            <td>93</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>708</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>684</td>
            <td>427</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">25.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>189</td>
            <td>82</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>648</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>529</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">26.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>210</td>
            <td>95</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>231</td>
            <td>734</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>717</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">27.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>179</td>
            <td>73</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>526</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">28.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>180</td>
            <td>74</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>419</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">29.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>216</td>
            <td>109</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>300</td>
            <td>916</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>829</td>
            <td>517</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">30.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>206</td>
            <td>93</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>245</td>
            <td>787</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>775</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">31.05.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>198</td>
            <td>94</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>776</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>696</td>
            <td>405</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">01.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>201</td>
            <td>95</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>836</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>657</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">02.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>225</td>
            <td>135</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>797</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>654</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">03.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>186</td>
            <td>76</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>510</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>462</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">04.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>190</td>
            <td>77</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>549</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>528</td>
            <td>336</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">05.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>190</td>
            <td>262</td>
            <td>135</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>338</td>
            <td>1001</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>752</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">06.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>241</td>
            <td>111</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>262</td>
            <td>810</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>657</td>
            <td>396</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">07.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>242</td>
            <td>112</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>286</td>
            <td>856</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>680</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">08.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>268</td>
            <td>133</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>346</td>
            <td>965</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>700</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">09.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>193</td>
            <td>80</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>624</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>644</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>185</td>
            <td>71</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>470</td>
            <td>291</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>190</td>
            <td>76</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>494</td>
            <td>306</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>219</td>
            <td>117</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>297</td>
            <td>918</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>761</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">13.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>192</td>
            <td>124</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>331</td>
            <td>884</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>692</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">14.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>184</td>
            <td>87</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>247</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>667</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">15.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>181</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>676</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>377</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">16.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>183</td>
            <td>79</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>350</td>
            <td>724</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">17.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>169</td>
            <td>66</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>466</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>479</td>
            <td>296</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">18.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>178</td>
            <td>69</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>510</td>
            <td>312</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">19.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>185</td>
            <td>83</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>346</td>
            <td>841</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>741</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">20.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>180</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>714</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>688</td>
            <td>391</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">21.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>181</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>228</td>
            <td>693</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>685</td>
            <td>387</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">22.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>179</td>
            <td>77</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>663</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>377</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">23.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>180</td>
            <td>77</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>241</td>
            <td>673</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>661</td>
            <td>372</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">24.06.2022</td>
            <td>Open Banking API</td>
            <td>82.00%</td>
            <td>18.00%</td>
            <td>89</td>
            <td>173</td>
            <td>65</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>82.00%</td>
            <td>18.00%</td>
            <td>208</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>82.00%</td>
            <td>8.00%</td>
            <td>648</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">25.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>176</td>
            <td>68</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>308</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">26.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>187</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>288</td>
            <td>817</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>712</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">27.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>180</td>
            <td>74</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>234</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>378</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">28.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>178</td>
            <td>75</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>695</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>642</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">29.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>179</td>
            <td>76</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>259</td>
            <td>739</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>671</td>
            <td>399</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">30.06.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>184</td>
            <td>78</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>247</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>667</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">01.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>255</td>
            <td>105</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>473</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1010</td>
            <td>728</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">02.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>242</td>
            <td>83</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>587</td>
            <td>545</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">03.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>251</td>
            <td>85</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>282</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>601</td>
            <td>548</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">04.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>265</td>
            <td>106</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>579</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1005</td>
            <td>727</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">05.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>266</td>
            <td>110</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>913</td>
            <td>639</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">06.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>257</td>
            <td>102</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>484</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>881</td>
            <td>680</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">07.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>262</td>
            <td>110</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>553</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>885</td>
            <td>688</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">08.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>291</td>
            <td>117</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>270</td>
            <td>568</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>896</td>
            <td>725</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">09.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>247</td>
            <td>98</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>299</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>530</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">10.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>251</td>
            <td>84</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>188</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>550</td>
            <td>513</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">11.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>175</td>
            <td>301</td>
            <td>128</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>302</td>
            <td>648</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1035</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">12.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>289</td>
            <td>123</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>933</td>
            <td>714</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">13.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>270</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>454</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>898</td>
            <td>677</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">14.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>266</td>
            <td>100</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>443</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>903</td>
            <td>691</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">15.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>262</td>
            <td>115</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>487</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1037</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">16.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>243</td>
            <td>85</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>274</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>508</td>
            <td>423</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">17.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>251</td>
            <td>87</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>533</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">18.07.2022</td>
            <td>Open Banking API</td>
            <td>92.00%</td>
            <td>8.00%</td>
            <td>159</td>
            <td>299</td>
            <td>107</td>
            <td>1.387%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>92.00%</td>
            <td>8.00%</td>
            <td>260</td>
            <td>1171</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>984</td>
            <td>713</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">19.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>272</td>
            <td>100</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>902</td>
            <td>658</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">20.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>252</td>
            <td>95</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>482</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>857</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">21.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>253</td>
            <td>112</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>202</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>868</td>
            <td>676</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">22.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>259</td>
            <td>107</td>
            <td>0.110%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>444</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>914</td>
            <td>757</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">23.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>222</td>
            <td>78</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>267</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>515</td>
            <td>458</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">24.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>234</td>
            <td>83</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>521</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">25.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>263</td>
            <td>290</td>
            <td>125</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>544</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1004</td>
            <td>758</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">26.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1171</td>
            <td>2412</td>
            <td>1127</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>194</td>
            <td>414</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>856</td>
            <td>642</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">27.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>254</td>
            <td>94</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>899</td>
            <td>650</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">28.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>252</td>
            <td>94</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>899</td>
            <td>674</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">29.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>270</td>
            <td>96</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>989</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">30.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>247</td>
            <td>77</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>263</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>540</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">31.07.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>252</td>
            <td>89</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>519</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">01.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>257</td>
            <td>84</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>178</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>648</td>
            <td>555</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">02.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>252</td>
            <td>85</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>168</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>690</td>
            <td>589</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">03.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>244</td>
            <td>92</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>502</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1107</td>
            <td>803</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">04.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>282</td>
            <td>107</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>248</td>
            <td>864</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>930</td>
            <td>695</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">05.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>278</td>
            <td>104</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>840</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>932</td>
            <td>735</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">06.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>244</td>
            <td>80</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>548</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>540</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">07.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>254</td>
            <td>79</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>571</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>570</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">08.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>166</td>
            <td>298</td>
            <td>112</td>
            <td>0.079%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>267</td>
            <td>983</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1039</td>
            <td>818</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">09.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>153</td>
            <td>316</td>
            <td>118</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>932</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>919</td>
            <td>698</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">10.08.2022</td>
            <td>Open Banking API</td>
            <td>93.00%</td>
            <td>7.00%</td>
            <td>452</td>
            <td>1100</td>
            <td>235</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>496</td>
            <td>1363</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>947</td>
            <td>745</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">11.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>266</td>
            <td>91</td>
            <td>0.019%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>794</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>903</td>
            <td>689</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">12.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>275</td>
            <td>97</td>
            <td>0.046%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>213</td>
            <td>807</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>918</td>
            <td>718</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">13.08.2022</td>
            <td>Open Banking API</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>117</td>
            <td>261</td>
            <td>78</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>138</td>
            <td>592</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>505</td>
            <td>432</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">14.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>282</td>
            <td>85</td>
            <td>0.063%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>170</td>
            <td>627</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>574</td>
            <td>505</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">15.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>377</td>
            <td>111</td>
            <td>0.260%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>287</td>
            <td>1049</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1089</td>
            <td>805</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">16.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>284</td>
            <td>89</td>
            <td>0.038%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>773</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>927</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">17.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>244</td>
            <td>88</td>
            <td>0.018%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>211</td>
            <td>711</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>918</td>
            <td>680</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">18.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>248</td>
            <td>89</td>
            <td>0.064%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>671</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>911</td>
            <td>702</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">19.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>308</td>
            <td>90</td>
            <td>0.024%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>199</td>
            <td>705</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>878</td>
            <td>700</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">20.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>243</td>
            <td>89</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>137</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>485</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">21.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>254</td>
            <td>84</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>531</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">22.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>281</td>
            <td>105</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>281</td>
            <td>924</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1192</td>
            <td>776</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">23.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>262</td>
            <td>89</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>234</td>
            <td>736</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>910</td>
            <td>666</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">24.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>264</td>
            <td>88</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>713</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>869</td>
            <td>647</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">25.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>265</td>
            <td>88</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>719</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>903</td>
            <td>677</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">26.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>284</td>
            <td>84</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>211</td>
            <td>717</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>886</td>
            <td>672</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">27.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>233</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>536</td>
            <td>478</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">28.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>246</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>558</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>625</td>
            <td>560</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">29.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>267</td>
            <td>97</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>247</td>
            <td>849</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1053</td>
            <td>774</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">30.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>253</td>
            <td>101</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>754</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1036</td>
            <td>774</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">31.08.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>250</td>
            <td>98</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>222</td>
            <td>772</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1059</td>
            <td>761</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">01.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>468</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>209</td>
            <td>725</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>905</td>
            <td>685</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">02.09.2022</td>
            <td>Open Banking API</td>
            <td>97.30%</td>
            <td>2.70%</td>
            <td>127</td>
            <td>337</td>
            <td>90</td>
            <td>0.115%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>732</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>849</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">03.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>222</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>494</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">04.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>247</td>
            <td>85</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>167</td>
            <td>509</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">05.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>290</td>
            <td>103</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>259</td>
            <td>885</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>988</td>
            <td>789</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">06.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>154</td>
            <td>280</td>
            <td>96</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>222</td>
            <td>761</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>911</td>
            <td>681</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">07.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>278</td>
            <td>98</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>763</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>853</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">08.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>283</td>
            <td>98</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>771</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>861</td>
            <td>686</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">09.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>157</td>
            <td>279</td>
            <td>105</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>256</td>
            <td>839</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>866</td>
            <td>705</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">10.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>238</td>
            <td>76</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>497</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>497</td>
            <td>457</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">11.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>247</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>539</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>549</td>
            <td>511</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">12.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>280</td>
            <td>105</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>269</td>
            <td>898</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1023</td>
            <td>826</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">13.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>337</td>
            <td>484</td>
            <td>356</td>
            <td>0.205%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>315</td>
            <td>1278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>912</td>
            <td>692</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">14.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>250</td>
            <td>97</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>252</td>
            <td>914</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>911</td>
            <td>709</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">15.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>282</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>259</td>
            <td>817</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>836</td>
            <td>645</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">16.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>330</td>
            <td>94</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>217</td>
            <td>968</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>825</td>
            <td>661</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">17.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>297</td>
            <td>75</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">18.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>308</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>172</td>
            <td>775</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">19.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>341</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>275</td>
            <td>1158</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>954</td>
            <td>743</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">20.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>316</td>
            <td>91</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>957</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>850</td>
            <td>670</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">21.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>325</td>
            <td>94</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>999</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>882</td>
            <td>692</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">22.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>332</td>
            <td>91</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>994</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>845</td>
            <td>690</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">23.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>291</td>
            <td>85</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>697</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>473</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">24.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>301</td>
            <td>66</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>439</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">25.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>312</td>
            <td>72</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>716</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>498</td>
            <td>481</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">26.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>340</td>
            <td>98</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>1086</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>987</td>
            <td>769</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">27.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>323</td>
            <td>94</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>954</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>892</td>
            <td>706</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">28.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>328</td>
            <td>94</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>950</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>912</td>
            <td>746</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">29.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>343</td>
            <td>103</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>245</td>
            <td>1041</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>989</td>
            <td>839</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-wrap">30.09.2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>320</td>
            <td>93</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>966</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>913</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/1/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>253</td>
            <td>104</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>189</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>708</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/2/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>240</td>
            <td>83</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>291</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>492</td>
            <td>368</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/3/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>249</td>
            <td>83</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>487</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/4/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>263</td>
            <td>106</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>577</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>746</td>
            <td>484</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/5/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>264</td>
            <td>109</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>706</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/6/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>255</td>
            <td>102</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>482</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>680</td>
            <td>450</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/7/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>260</td>
            <td>109</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>190</td>
            <td>551</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>681</td>
            <td>460</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/8/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>289</td>
            <td>117</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>262</td>
            <td>566</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>701</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/9/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>245</td>
            <td>94</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>472</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/10/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>249</td>
            <td>95</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>369</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>494</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/11/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>167</td>
            <td>299</td>
            <td>128</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>294</td>
            <td>646</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>786</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/12/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>151</td>
            <td>287</td>
            <td>123</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>680</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/13/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>268</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>207</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/14/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>264</td>
            <td>100</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>441</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>675</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/15/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>260</td>
            <td>105</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>485</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>686</td>
            <td>466</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/16/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>241</td>
            <td>85</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>272</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>453</td>
            <td>332</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/17/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>249</td>
            <td>85</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>291</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>472</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/18/2022</td>
            <td>Open Banking API</td>
            <td>92.00%</td>
            <td>8.00%</td>
            <td>151</td>
            <td>297</td>
            <td>107</td>
            <td>1.387%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>92.00%</td>
            <td>8.00%</td>
            <td>252</td>
            <td>1169</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>706</td>
            <td>463</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/19/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>270</td>
            <td>100</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>463</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>660</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/20/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>250</td>
            <td>95</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>660</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/21/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>251</td>
            <td>102</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>194</td>
            <td>440</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>648</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/22/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>257</td>
            <td>107</td>
            <td>0.110%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>671</td>
            <td>460</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/23/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>220</td>
            <td>78</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>265</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>454</td>
            <td>339</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/24/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>232</td>
            <td>83</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>461</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/25/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>255</td>
            <td>288</td>
            <td>135</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>229</td>
            <td>542</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>704</td>
            <td>464</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/26/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1163</td>
            <td>2410</td>
            <td>1127</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>186</td>
            <td>412</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>648</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/27/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>252</td>
            <td>94</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>189</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>658</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/28/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>250</td>
            <td>94</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>190</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>659</td>
            <td>436</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/29/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>268</td>
            <td>96</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>460</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>678</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/30/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>245</td>
            <td>77</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>261</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>469</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/31/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>250</td>
            <td>79</td>
            <td>0.000%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>499</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/1/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>255</td>
            <td>84</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>170</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>640</td>
            <td>553</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/2/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>250</td>
            <td>85</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>682</td>
            <td>587</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/3/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>242</td>
            <td>92</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>219</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1099</td>
            <td>801</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/4/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>280</td>
            <td>107</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>862</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>922</td>
            <td>693</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/5/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>276</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>838</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>924</td>
            <td>733</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/6/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>242</td>
            <td>80</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>546</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>532</td>
            <td>499</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/7/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>252</td>
            <td>79</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>569</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>562</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/8/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>296</td>
            <td>112</td>
            <td>0.079%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>259</td>
            <td>981</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1031</td>
            <td>816</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/9/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>314</td>
            <td>118</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>232</td>
            <td>930</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>911</td>
            <td>696</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/10/2022</td>
            <td>Open Banking API</td>
            <td>93.00%</td>
            <td>7.00%</td>
            <td>444</td>
            <td>1098</td>
            <td>323</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>488</td>
            <td>1361</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>939</td>
            <td>743</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/11/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>264</td>
            <td>91</td>
            <td>0.019%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>792</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>895</td>
            <td>687</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/12/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>273</td>
            <td>97</td>
            <td>0.046%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>205</td>
            <td>805</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>910</td>
            <td>716</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/13/2022</td>
            <td>Open Banking API</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>109</td>
            <td>259</td>
            <td>78</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>130</td>
            <td>590</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>497</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/14/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>280</td>
            <td>80</td>
            <td>0.063%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>625</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>566</td>
            <td>503</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/15/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>375</td>
            <td>111</td>
            <td>0.260%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>279</td>
            <td>1047</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1081</td>
            <td>803</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/16/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>282</td>
            <td>89</td>
            <td>0.038%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>771</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>919</td>
            <td>676</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/17/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>242</td>
            <td>88</td>
            <td>0.018%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>203</td>
            <td>709</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>910</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/18/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>246</td>
            <td>89</td>
            <td>0.064%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>669</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>903</td>
            <td>700</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/19/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>306</td>
            <td>90</td>
            <td>0.024%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>191</td>
            <td>703</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>870</td>
            <td>698</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/20/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>241</td>
            <td>75</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>489</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>512</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/21/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>252</td>
            <td>84</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>166</td>
            <td>534</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>450</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/22/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>279</td>
            <td>105</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>273</td>
            <td>922</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1184</td>
            <td>774</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/23/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>260</td>
            <td>91</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>226</td>
            <td>734</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>902</td>
            <td>664</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/24/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>262</td>
            <td>88</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>202</td>
            <td>711</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>861</td>
            <td>645</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/25/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>263</td>
            <td>88</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>717</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>895</td>
            <td>675</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/26/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>282</td>
            <td>99</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>203</td>
            <td>715</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>878</td>
            <td>670</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/27/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>231</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>499</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>528</td>
            <td>476</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/28/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>244</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>171</td>
            <td>556</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>617</td>
            <td>558</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/29/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>265</td>
            <td>97</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>239</td>
            <td>847</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1045</td>
            <td>772</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/30/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>251</td>
            <td>101</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>752</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1028</td>
            <td>772</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/1/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>248</td>
            <td>93</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>770</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>896</td>
            <td>554</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/2/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>466</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>201</td>
            <td>723</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>789</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/3/2022</td>
            <td>Open Banking API</td>
            <td>97.29%</td>
            <td>2.71%</td>
            <td>119</td>
            <td>335</td>
            <td>90</td>
            <td>0.115%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>730</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>726</td>
            <td>514</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/4/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>220</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>460</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>522</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/5/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>245</td>
            <td>75</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>507</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>589</td>
            <td>455</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/6/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>288</td>
            <td>103</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>883</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>876</td>
            <td>616</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/7/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>278</td>
            <td>96</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>759</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>773</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/8/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>276</td>
            <td>98</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>219</td>
            <td>761</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>732</td>
            <td>499</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/9/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>281</td>
            <td>100</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>769</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>700</td>
            <td>453</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/10/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>149</td>
            <td>277</td>
            <td>105</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>248</td>
            <td>837</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>734</td>
            <td>487</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/11/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>236</td>
            <td>76</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>517</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/12/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>245</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>537</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>565</td>
            <td>422</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/13/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>278</td>
            <td>105</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>261</td>
            <td>896</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>846</td>
            <td>565</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/14/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>329</td>
            <td>482</td>
            <td>408</td>
            <td>0.205%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>307</td>
            <td>1276</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>762</td>
            <td>459</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/15/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>248</td>
            <td>97</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>244</td>
            <td>912</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>715</td>
            <td>460</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/16/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>280</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>815</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>697</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/17/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>328</td>
            <td>94</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>209</td>
            <td>966</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>687</td>
            <td>436</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/18/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>295</td>
            <td>75</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>727</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>499</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/19/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>306</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>773</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>543</td>
            <td>384</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/20/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>339</td>
            <td>105</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>267</td>
            <td>1156</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>791</td>
            <td>518</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/21/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>314</td>
            <td>91</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>955</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>709</td>
            <td>457</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/22/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>323</td>
            <td>94</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>224</td>
            <td>997</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>715</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/23/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>330</td>
            <td>91</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>992</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>662</td>
            <td>436</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/24/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>289</td>
            <td>72</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>695</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>473</td>
            <td>352</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/25/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>299</td>
            <td>66</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>658</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>481</td>
            <td>351</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/26/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>310</td>
            <td>72</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>714</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>388</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/27/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>338</td>
            <td>98</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>250</td>
            <td>1084</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>789</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/28/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>321</td>
            <td>91</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>952</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>709</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/29/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>326</td>
            <td>94</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>948</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>724</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/30/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>341</td>
            <td>103</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>1039</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>755</td>
            <td>518</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/31/2022</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>318</td>
            <td>93</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>202</td>
            <td>964</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>674</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>75</td>
            <td>70</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>415</td>
            <td>328</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>72</td>
            <td>78</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>610</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>616</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>69</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>529</td>
            <td>362</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>75</td>
            <td>73</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>632</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>78</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>537</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>81</td>
            <td>74</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>505</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>377</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>108</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>152</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>186</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>77</td>
            <td>69</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>413</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>80</td>
            <td>78</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>637</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>565</td>
            <td>397</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>80</td>
            <td>80</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>223</td>
            <td>571</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>544</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>83</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>490</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>496</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>53</td>
            <td>75</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>478</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>492</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>54</td>
            <td>76</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>186</td>
            <td>515</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>505</td>
            <td>357</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>52</td>
            <td>65</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>339</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>352</td>
            <td>284</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>61</td>
            <td>70</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>156</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>399</td>
            <td>322</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>70</td>
            <td>81</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>678</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>610</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>70</td>
            <td>76</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>516</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>524</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>72</td>
            <td>74</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>519</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>518</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>76</td>
            <td>73</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>498</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>511</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>78</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/21/2023</td>
            <td>Open Banking API</td>
            <td>99.44%</td>
            <td>0.56%</td>
            <td>111</td>
            <td>86</td>
            <td>66</td>
            <td>0.039%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.88%</td>
            <td>0.12%</td>
            <td>154</td>
            <td>367</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>346</td>
            <td>274</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>57</td>
            <td>72</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>344</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>410</td>
            <td>326</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>159</td>
            <td>162</td>
            <td>211</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>256</td>
            <td>592</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.09%</td>
            <td>0.91%</td>
            <td>620</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>53</td>
            <td>72</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>477</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>353</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>63</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>349</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>61</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>188</td>
            <td>474</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>516</td>
            <td>351</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>97</td>
            <td>90</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>489</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.76%</td>
            <td>0.24%</td>
            <td>525</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>62</td>
            <td>67</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>372</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>72</td>
            <td>72</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>362</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>405</td>
            <td>327</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>314</td>
            <td>75</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>609</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>587</td>
            <td>398</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">1/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>76</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>572</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>559</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>48</td>
            <td>77</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>187</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>728</td>
            <td>530</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>51</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>524</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>703</td>
            <td>527</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>57</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>522</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>662</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>52</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>335</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>395</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>64</td>
            <td>73</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>476</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>69</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>621</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>740</td>
            <td>588</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>67</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>504</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>676</td>
            <td>545</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>70</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>518</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>677</td>
            <td>541</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>71</td>
            <td>77</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>542</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>697</td>
            <td>555</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>376</td>
            <td>553</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1033</td>
            <td>1174</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>706</td>
            <td>595</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>71</td>
            <td>66</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>330</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>407</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>48</td>
            <td>70</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>450</td>
            <td>402</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>55</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>630</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>750</td>
            <td>578</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>59</td>
            <td>77</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>221</td>
            <td>551</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>710</td>
            <td>545</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>68</td>
            <td>80</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>205</td>
            <td>556</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>709</td>
            <td>570</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>103</td>
            <td>72</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>161</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>443</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>94</td>
            <td>79</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>192</td>
            <td>511</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>532</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>79</td>
            <td>69</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>378</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>441</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>85</td>
            <td>70</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>155</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>457</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>125</td>
            <td>87</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>655</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>779</td>
            <td>604</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>124</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>512</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>673</td>
            <td>514</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>102</td>
            <td>82</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>180</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>703</td>
            <td>534</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>64</td>
            <td>77</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>173</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>673</td>
            <td>512</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>72</td>
            <td>78</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>174</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>687</td>
            <td>526</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>66</td>
            <td>68</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>342</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>407</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>99</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>148</td>
            <td>368</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>453</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>115</td>
            <td>114</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>236</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>775</td>
            <td>591</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">2/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>67</td>
            <td>84</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>221</td>
            <td>562</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>772</td>
            <td>579</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>74</td>
            <td>83</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>217</td>
            <td>550</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>626</td>
            <td>363</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>77</td>
            <td>83</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>544</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>591</td>
            <td>356</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>81</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>218</td>
            <td>533</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>75</td>
            <td>44</td>
            <td>69</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>314</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>392</td>
            <td>261</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>53</td>
            <td>74</td>
            <td>0.033%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>331</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>443</td>
            <td>300</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/6/2023</td>
            <td>Open Banking API</td>
            <td>99.97%</td>
            <td>0.03%</td>
            <td>100</td>
            <td>68</td>
            <td>92</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.97%</td>
            <td>0.03%</td>
            <td>252</td>
            <td>708</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>631</td>
            <td>389</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>57</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>191</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>557</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>59</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>200</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>61</td>
            <td>80</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>513</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>587</td>
            <td>338</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>66</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>578</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>615</td>
            <td>374</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>61</td>
            <td>68</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>147</td>
            <td>312</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>423</td>
            <td>283</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>72</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>325</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>84</td>
            <td>84</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>254</td>
            <td>655</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>669</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>90</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>548</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>591</td>
            <td>341</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>87</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>704</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>585</td>
            <td>339</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>101</td>
            <td>85</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>693</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>590</td>
            <td>346</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>67</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>500</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>571</td>
            <td>332</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/18/2023</td>
            <td>Open Banking API</td>
            <td>79.55%</td>
            <td>20.45%</td>
            <td>84</td>
            <td>58</td>
            <td>67</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>79.55%</td>
            <td>20.45%</td>
            <td>143</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>79.27%</td>
            <td>20.73%</td>
            <td>423</td>
            <td>260</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>62</td>
            <td>67</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>137</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>435</td>
            <td>272</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>81</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>233</td>
            <td>629</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>619</td>
            <td>352</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>74</td>
            <td>75</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>170</td>
            <td>508</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>572</td>
            <td>322</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>77</td>
            <td>74</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>472</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>569</td>
            <td>323</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>77</td>
            <td>71</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>456</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>569</td>
            <td>320</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>85</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>542</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>72</td>
            <td>47</td>
            <td>64</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>312</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>384</td>
            <td>244</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>54</td>
            <td>66</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>421</td>
            <td>262</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>66</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>2786</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>763</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>68</td>
            <td>79</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>313</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>193</td>
            <td>287</td>
            <td>121</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>765</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>572</td>
            <td>310</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>59</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>398</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>563</td>
            <td>313</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">3/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>63</td>
            <td>76</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>571</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>74</td>
            <td>39</td>
            <td>N/A</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>267</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>369</td>
            <td>275</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>43</td>
            <td>66</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>293</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>395</td>
            <td>288</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>51</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>517</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>590</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>51</td>
            <td>74</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>524</td>
            <td>360</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>58</td>
            <td>77</td>
            <td>0.045%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>521</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>60</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>136</td>
            <td>499</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>381</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>62</td>
            <td>73</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>412</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>383</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>59</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>368</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>63</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>277</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>378</td>
            <td>296</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>68</td>
            <td>74</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>137</td>
            <td>401</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>417</td>
            <td>308</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>79</td>
            <td>80</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>576</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>348</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>55</td>
            <td>76</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>344</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>49</td>
            <td>76</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>510</td>
            <td>337</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>50</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>443</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>82</td>
            <td>47</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>359</td>
            <td>278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>53</td>
            <td>67</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>299</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>390</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>59</td>
            <td>80</td>
            <td>0.021%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>549</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>370</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/18/2023</td>
            <td>Open Banking API</td>
            <td>97.98%</td>
            <td>2.02%</td>
            <td>109</td>
            <td>60</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>426</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>64</td>
            <td>75</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>528</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>67</td>
            <td>75</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>527</td>
            <td>348</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/21/2023</td>
            <td>Open Banking API</td>
            <td>99.92%</td>
            <td>0.08%</td>
            <td>115</td>
            <td>71</td>
            <td>76</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>507</td>
            <td>327</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>68</td>
            <td>34</td>
            <td>51</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>241</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>316</td>
            <td>251</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>39</td>
            <td>54</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>255</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>337</td>
            <td>261</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>46</td>
            <td>66</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>450</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>531</td>
            <td>343</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>48</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>496</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>51</td>
            <td>62</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>503</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/27/2023</td>
            <td>Open Banking API</td>
            <td>99.95%</td>
            <td>0.05%</td>
            <td>98</td>
            <td>82</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>551</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>57</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>356</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>57</td>
            <td>55</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>281</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>365</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">4/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>58</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>289</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>382</td>
            <td>298</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>59</td>
            <td>52</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>286</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>470</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/2/2023</td>
            <td>Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>99</td>
            <td>53</td>
            <td>88</td>
            <td>0.024%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>1417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>736</td>
            <td>517</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>79</td>
            <td>37</td>
            <td>61</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>667</td>
            <td>494</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>80</td>
            <td>39</td>
            <td>60</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>347</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>634</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>81</td>
            <td>44</td>
            <td>60</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>610</td>
            <td>491</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>43</td>
            <td>52</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>265</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>391</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>78</td>
            <td>48</td>
            <td>53</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>270</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>402</td>
            <td>362</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>52</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>745</td>
            <td>535</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>55</td>
            <td>61</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>673</td>
            <td>504</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/10/2023</td>
            <td>Open Banking API</td>
            <td>99.74%</td>
            <td>0.26%</td>
            <td>99</td>
            <td>62</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>657</td>
            <td>506</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>63</td>
            <td>63</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>413</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>657</td>
            <td>503</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>66</td>
            <td>62</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>382</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>621</td>
            <td>476</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/13/2023</td>
            <td>Open Banking API</td>
            <td>82.26%</td>
            <td>17.74%</td>
            <td>62</td>
            <td>35</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>83.75%</td>
            <td>16.25%</td>
            <td>92</td>
            <td>287</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>82.50%</td>
            <td>17.50%</td>
            <td>380</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>68</td>
            <td>38</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>262</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>396</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>46</td>
            <td>67</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>750</td>
            <td>535</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>50</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>688</td>
            <td>495</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>51</td>
            <td>62</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>683</td>
            <td>501</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>53</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>261</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>672</td>
            <td>496</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>56</td>
            <td>62</td>
            <td>0.022%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>253</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>640</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>55</td>
            <td>53</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>274</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>391</td>
            <td>351</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>59</td>
            <td>54</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>278</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>415</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>66</td>
            <td>65</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>473</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>695</td>
            <td>493</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/23/2023</td>
            <td>Open Banking API</td>
            <td>99.50%</td>
            <td>0.50%</td>
            <td>103</td>
            <td>35</td>
            <td>62</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>346</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>666</td>
            <td>477</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>39</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>352</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>43</td>
            <td>63</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>668</td>
            <td>493</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>46</td>
            <td>61</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>352</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>647</td>
            <td>484</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>45</td>
            <td>53</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>255</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>398</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>49</td>
            <td>55</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>266</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>409</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>55</td>
            <td>64</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>437</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>725</td>
            <td>509</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>59</td>
            <td>63</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>375</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>680</td>
            <td>497</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">5/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>62</td>
            <td>66</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>710</td>
            <td>511</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>64</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>142</td>
            <td>408</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>588</td>
            <td>315</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>76</td>
            <td>37</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>533</td>
            <td>285</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>68</td>
            <td>36</td>
            <td>55</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>250</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>358</td>
            <td>226</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>73</td>
            <td>40</td>
            <td>54</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>252</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>385</td>
            <td>237</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>48</td>
            <td>66</td>
            <td>0.062%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>542</td>
            <td>299</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>49</td>
            <td>66</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>386</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>506</td>
            <td>281</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>52</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>521</td>
            <td>291</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/8/2023</td>
            <td>Open Banking API</td>
            <td>98.89%</td>
            <td>1.11%</td>
            <td>93</td>
            <td>56</td>
            <td>66</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>405</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>295</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>58</td>
            <td>65</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>520</td>
            <td>298</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>84</td>
            <td>54</td>
            <td>54</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>279</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>373</td>
            <td>238</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>59</td>
            <td>55</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>283</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>402</td>
            <td>253</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>67</td>
            <td>70</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>497</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>294</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>77</td>
            <td>38</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>383</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>518</td>
            <td>271</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>84</td>
            <td>41</td>
            <td>63</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>281</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>45</td>
            <td>64</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>400</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>522</td>
            <td>282</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>47</td>
            <td>62</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>523</td>
            <td>280</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/17/2023</td>
            <td>Open Banking API</td>
            <td>81.84%</td>
            <td>18.16%</td>
            <td>79</td>
            <td>47</td>
            <td>54</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.65%</td>
            <td>14.35%</td>
            <td>370</td>
            <td>225</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>51</td>
            <td>55</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>273</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>403</td>
            <td>242</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>57</td>
            <td>64</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>350</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>564</td>
            <td>303</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>62</td>
            <td>64</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>129</td>
            <td>245</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>536</td>
            <td>297</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>63</td>
            <td>62</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>135</td>
            <td>235</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>535</td>
            <td>292</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>66</td>
            <td>61</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>236</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>483</td>
            <td>259</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>36</td>
            <td>58</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>198</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>385</td>
            <td>225</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>67</td>
            <td>37</td>
            <td>54</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>177</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>351</td>
            <td>212</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>70</td>
            <td>43</td>
            <td>54</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>190</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>383</td>
            <td>230</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>48</td>
            <td>64</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>236</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>554</td>
            <td>292</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>51</td>
            <td>63</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>227</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>534</td>
            <td>283</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/28/2023</td>
            <td>Open Banking API</td>
            <td>99.93%</td>
            <td>0.07%</td>
            <td>100</td>
            <td>54</td>
            <td>64</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>230</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>529</td>
            <td>287</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>103</td>
            <td>56</td>
            <td>64</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>232</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>538</td>
            <td>290</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">6/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>58</td>
            <td>65</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>235</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>543</td>
            <td>304</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>197</td>
            <td>96</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>257</td>
            <td>829</td>
            <td>N/A</td>
            <td>0.021%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>710</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>190</td>
            <td>86</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>559</td>
            <td>N/A</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>514</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>197</td>
            <td>87</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>201</td>
            <td>554</td>
            <td>N/A</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>536</td>
            <td>371</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>201</td>
            <td>98</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>292</td>
            <td>947</td>
            <td>N/A</td>
            <td>0.027%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>753</td>
            <td>480</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>204</td>
            <td>97</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>284</td>
            <td>859</td>
            <td>N/A</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>698</td>
            <td>458</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>193</td>
            <td>84</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>662</td>
            <td>N/A</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>671</td>
            <td>429</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/7/2023</td>
            <td>Open Banking API</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>259</td>
            <td>450</td>
            <td>322</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>1347</td>
            <td>2276</td>
            <td>N/A</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>802</td>
            <td>498</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>209</td>
            <td>100</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>2043</td>
            <td>2449</td>
            <td>N/A</td>
            <td>0.035%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>3027</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>183</td>
            <td>85</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>166</td>
            <td>546</td>
            <td>N/A</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>485</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>201</td>
            <td>89</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>218</td>
            <td>587</td>
            <td>N/A</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>550</td>
            <td>380</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>211</td>
            <td>113</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>3434</td>
            <td>4197</td>
            <td>N/A</td>
            <td>0.044%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>3950</td>
            <td>563</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>205</td>
            <td>100</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>300</td>
            <td>913</td>
            <td>N/A</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>744</td>
            <td>507</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>203</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>254</td>
            <td>785</td>
            <td>N/A</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>685</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>210</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>807</td>
            <td>N/A</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>661</td>
            <td>428</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>209</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>265</td>
            <td>850</td>
            <td>N/A</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>427</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>105</td>
            <td>198</td>
            <td>85</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>540</td>
            <td>N/A</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>540</td>
            <td>N/A</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>209</td>
            <td>88</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>563</td>
            <td>N/A</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td >100.00%</td>
            <td >0.00%</td>
            <td >536</td>
            <td >365</td>
            <td >N/A</td>
            <td >N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>208</td>
            <td>96</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>914</td>
            <td>N/A</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>729</td>
            <td>461</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>211</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>234</td>
            <td>758</td>
            <td>N/A</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>654</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>205</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>782</td>
            <td>N/A</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>641</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>205</td>
            <td>91</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>735</td>
            <td>N/A</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>629</td>
            <td>390</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>207</td>
            <td>109</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>231</td>
            <td>743</td>
            <td>N/A</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>636</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>198</td>
            <td>85</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>158</td>
            <td>528</td>
            <td>N/A</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>483</td>
            <td>322</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>205</td>
            <td>89</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>560</td>
            <td>N/A</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>529</td>
            <td>355</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>207</td>
            <td>99</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>267</td>
            <td>876</td>
            <td>N/A</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>707</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>206</td>
            <td>94</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>230</td>
            <td>744</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>642</td>
            <td>393</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>205</td>
            <td>92</td>
            <td>0.118%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>226</td>
            <td>732</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>633</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/28/2023</td>
            <td>Open Banking API</td>
            <td >100.00%</td>
            <td >0.00%</td>
            <td >116</td>
            <td >205</td>
            <td >96</td>
            <td >0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>630</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>207</td>
            <td>99</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>656</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>109</td>
            <td>196</td>
            <td>88</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>541</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>498</td>
            <td>327</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">7/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>206</td>
            <td>95</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>203</td>
            <td>591</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>561</td>
            <td>379</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>209</td>
            <td>100</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>300</td>
            <td>969</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>662</td>
            <td>452</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>200</td>
            <td>96</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>250</td>
            <td>826</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>588</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>204</td>
            <td>98</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>789</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>575</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>207</td>
            <td>98</td>
            <td>0.028%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>777</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>560</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>211</td>
            <td>102</td>
            <td>0.018%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>296</td>
            <td>877</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>414</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>200</td>
            <td>89</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>164</td>
            <td>555</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>409</td>
            <td>329</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>207</td>
            <td>93</td>
            <td>0.021%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>198</td>
            <td>579</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>440</td>
            <td>345</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>133</td>
            <td>208</td>
            <td>116</td>
            <td>0.020%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>384</td>
            <td>1211</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>712</td>
            <td>474</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>208</td>
            <td>132</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>297</td>
            <td>901</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>583</td>
            <td>416</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>210</td>
            <td>200</td>
            <td>0.027%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>389</td>
            <td>1024</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>595</td>
            <td>425</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>207</td>
            <td>100</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>830</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>579</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>206</td>
            <td>105</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>275</td>
            <td>830</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>589</td>
            <td>412</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>196</td>
            <td>92</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>162</td>
            <td>537</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>428</td>
            <td>333</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>205</td>
            <td>98</td>
            <td>0.035%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>205</td>
            <td>587</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>462</td>
            <td>346</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>200</td>
            <td>100</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>731</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>632</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>126</td>
            <td>208</td>
            <td>99</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>827</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>611</td>
            <td>427</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/17/2023</td>
            <td>Open Banking API</td>
            <td>95.80%</td>
            <td>4.20%</td>
            <td>159</td>
            <td>283</td>
            <td>153</td>
            <td>0.044%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>242</td>
            <td>882</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>591</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>211</td>
            <td>97</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>745</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>582</td>
            <td>394</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>211</td>
            <td>98</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>239</td>
            <td>751</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>568</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>200</td>
            <td>87</td>
            <td>0.016%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>533</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>419</td>
            <td>324</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>211</td>
            <td>91</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>188</td>
            <td>592</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>483</td>
            <td>359</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>218</td>
            <td>100</td>
            <td>0.013%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>297</td>
            <td>910</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>670</td>
            <td>448</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>213</td>
            <td>99</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>762</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>600</td>
            <td>405</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/24/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>121</td>
            <td>210</td>
            <td>97</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>738</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>606</td>
            <td>401</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>213</td>
            <td>98</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>257</td>
            <td>796</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>604</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>210</td>
            <td>97</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>233</td>
            <td>723</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>593</td>
            <td>402</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>205</td>
            <td>91</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>163</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>452</td>
            <td>326</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>128</td>
            <td>210</td>
            <td>93</td>
            <td>0.015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>184</td>
            <td>574</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>472</td>
            <td>349</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>214</td>
            <td>102</td>
            <td>0.017%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>287</td>
            <td>899</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>664</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/30/2023</td>
            <td>Open Banking API</td>
            <td>98.00%</td>
            <td>2.00%</td>
            <td>124</td>
            <td>214</td>
            <td>104</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>832</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>630</td>
            <td>411</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/31/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>121</td>
            <td>212</td>
            <td>102</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>299</td>
            <td>862</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>626</td>
            <td>420</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>127</td>
            <td>211</td>
            <td>101</td>
            <td>0.012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>296</td>
            <td>863</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>866</td>
            <td>648</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>216</td>
            <td>107</td>
            <td>0.011%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>292</td>
            <td>882</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>830</td>
            <td>659</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>202</td>
            <td>95</td>
            <td>0.036%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>598</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>458</td>
            <td>409</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>214</td>
            <td>95</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>194</td>
            <td>603</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>495</td>
            <td>435</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>223</td>
            <td>111</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1061</td>
            <td>1757</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>996</td>
            <td>784</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>214</td>
            <td>99</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>280</td>
            <td>823</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>869</td>
            <td>672</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/7/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>115</td>
            <td>212</td>
            <td>96</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>299</td>
            <td>825</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>781</td>
            <td>625</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>217</td>
            <td>107</td>
            <td>0.028%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>332</td>
            <td>881</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>898</td>
            <td>720</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>134</td>
            <td>230</td>
            <td>135</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>98.00%</td>
            <td>2.00%</td>
            <td>1422</td>
            <td>1722</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>993</td>
            <td>818</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/10/2023</td>
            <td>Open Banking API</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>101</td>
            <td>198</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>159</td>
            <td>530</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>406</td>
            <td>395</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>110</td>
            <td>208</td>
            <td>88</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>213</td>
            <td>594</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>469</td>
            <td>437</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>222</td>
            <td>100</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>350</td>
            <td>1071</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>855</td>
            <td>703</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>218</td>
            <td>96</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>246</td>
            <td>788</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>773</td>
            <td>627</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>210</td>
            <td>93</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>766</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>775</td>
            <td>625</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>208</td>
            <td>95</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>816</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>836</td>
            <td>653</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>203</td>
            <td>95</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>237</td>
            <td>759</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>743</td>
            <td>603</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>196</td>
            <td>85</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>536</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>436</td>
            <td>411</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>210</td>
            <td>90</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>585</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>499</td>
            <td>463</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>116</td>
            <td>211</td>
            <td>99</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>269</td>
            <td>887</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>830</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>210</td>
            <td>96</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>239</td>
            <td>773</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>755</td>
            <td>605</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>207</td>
            <td>92</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>236</td>
            <td>757</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>887</td>
            <td>591</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>205</td>
            <td>101</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>733</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>802</td>
            <td>613</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>209</td>
            <td>102</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>244</td>
            <td>752</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>794</td>
            <td>639</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>114</td>
            <td>200</td>
            <td>89</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>529</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>462</td>
            <td>417</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>207</td>
            <td>93</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>227</td>
            <td>597</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>519</td>
            <td>468</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>211</td>
            <td>104</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>279</td>
            <td>887</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>886</td>
            <td>706</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>120</td>
            <td>207</td>
            <td>102</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>241</td>
            <td>754</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>784</td>
            <td>601</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>210</td>
            <td>105</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>251</td>
            <td>774</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>836</td>
            <td>617</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>130</td>
            <td>214</td>
            <td>128</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>278</td>
            <td>775</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>853</td>
            <td>626</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>219</td>
            <td>107</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>293</td>
            <td>896</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>878</td>
            <td>687</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/1/2023</td>
            <td>Open Banking API</td>
            <td >100.00%</td>
            <td >0.00%</td>
            <td >119</td>
            <td >214</td>
            <td >103</td>
            <td >0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>171</td>
            <td>570</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>171</td>
            <td>416</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>171</td>
            <td>202</td>
            <td>89</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>171</td>
            <td>644</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>525</td>
            <td>441</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>160</td>
            <td>344</td>
            <td>196</td>
            <td>0.072%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>334</td>
            <td>1268</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1054</td>
            <td>765</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>209</td>
            <td>100</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>262</td>
            <td>816</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>850</td>
            <td>658</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>124</td>
            <td>219</td>
            <td>104</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>289</td>
            <td>882</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>804</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>214</td>
            <td>107</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>265</td>
            <td>825</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>805</td>
            <td>640</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>221</td>
            <td>105</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>308</td>
            <td>906</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>816</td>
            <td>669</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>193</td>
            <td>87</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>169</td>
            <td>557</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>479</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>118</td>
            <td>209</td>
            <td>95</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>257</td>
            <td>647</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>575</td>
            <td>484</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>255</td>
            <td>118</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>943</td>
            <td>1696</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1097</td>
            <td>863</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>216</td>
            <td>105</td>
            <td>0.0015%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>287</td>
            <td>909</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>851</td>
            <td>669</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>123</td>
            <td>216</td>
            <td>104</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>272</td>
            <td>872</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>815</td>
            <td>641</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>122</td>
            <td>206</td>
            <td>102</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>258</td>
            <td>817</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>798</td>
            <td>630</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>125</td>
            <td>246</td>
            <td>110</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>286</td>
            <td>937</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>813</td>
            <td>670</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/15/2023</td>
            <td>Open Banking API</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>102</td>
            <td>179</td>
            <td>86</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>244</td>
            <td>734</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>85.00%</td>
            <td>15.00%</td>
            <td>660</td>
            <td>673</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>193</td>
            <td>89</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>212</td>
            <td>525</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>488</td>
            <td>433</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/17/2023</td>
            <td>Open Banking API</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>139</td>
            <td>166</td>
            <td>155</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>324</td>
            <td>1059</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>80.00%</td>
            <td>20.00%</td>
            <td>6532</td>
            <td>709</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/18/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>135</td>
            <td>193</td>
            <td>152</td>
            <td>0.025%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>285</td>
            <td>904</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>1366</td>
            <td>704</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>113</td>
            <td>175</td>
            <td>97</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>253</td>
            <td>712</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>820</td>
            <td>622</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>119</td>
            <td>191</td>
            <td>103</td>
            <td>0.005%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>759</td>
            <td>910</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>808</td>
            <td>626</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>183</td>
            <td>93</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>231</td>
            <td>662</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>796</td>
            <td>614</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>174</td>
            <td>84</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>165</td>
            <td>484</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>482</td>
            <td>419</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>111</td>
            <td>183</td>
            <td>87</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>505</td>
            <td>449</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>115</td>
            <td>187</td>
            <td>98</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>270</td>
            <td>791</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>902</td>
            <td>709</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>188</td>
            <td>93</td>
            <td>0.001%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>694</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>806</td>
            <td>667</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>112</td>
            <td>192</td>
            <td>93</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>664</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>783</td>
            <td>618</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>107</td>
            <td>189</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>234</td>
            <td>632</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>792</td>
            <td>631</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>191</td>
            <td>93</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>613</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>791</td>
            <td>631</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/29/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>94</td>
            <td>182</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>12</td>
            <td>16</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>428</td>
            <td>405</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>102</td>
            <td>190</td>
            <td>84</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>12</td>
            <td>16</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>451</td>
            <td>431</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">10/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>106</td>
            <td>191</td>
            <td>91</td>
            <td>0.0012%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>217</td>
            <td>641</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>765</td>
            <td>626</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>173</td>
            <td>77</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>190</td>
            <td>574</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>472</td>
            <td>430</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>172</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>185</td>
            <td>591</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>503</td>
            <td>447</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>144</td>
            <td>263</td>
            <td>134</td>
            <td>0.014%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>241</td>
            <td>690</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>914</td>
            <td>693</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>117</td>
            <td>216</td>
            <td>98</td>
            <td>0.010%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>253</td>
            <td>755</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>816</td>
            <td>675</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>85</td>
            <td>174</td>
            <td>73</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>138</td>
            <td>451</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>440</td>
            <td>407</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>177</td>
            <td>76</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>168</td>
            <td>464</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>453</td>
            <td>418</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>184</td>
            <td>84</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>201</td>
            <td>641</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>804</td>
            <td>658</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>183</td>
            <td>85</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>238</td>
            <td>679</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>756</td>
            <td>619</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>176</td>
            <td>85</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>239</td>
            <td>672</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>765</td>
            <td>625</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/10/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>169</td>
            <td>87</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>268</td>
            <td>729</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>764</td>
            <td>629</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>108</td>
            <td>184</td>
            <td>86</td>
            <td>0.0075%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>240</td>
            <td>706</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>919</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>87</td>
            <td>161</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>450</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>428</td>
            <td>391</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>174</td>
            <td>78</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>181</td>
            <td>473</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>472</td>
            <td>438</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>173</td>
            <td>88</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>266</td>
            <td>813</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>888</td>
            <td>701</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>121</td>
            <td>257</td>
            <td>240</td>
            <td>0.009%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>724</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>796</td>
            <td>636</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/16/2023</td>
            <td>Open Banking API</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>248</td>
            <td>430</td>
            <td>615</td>
            <td>0.030%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>226</td>
            <td>710</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>99.00%</td>
            <td>1.00%</td>
            <td>757</td>
            <td>573</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/17/2023</td>
            <td>Open Banking API</td>
            <td>99.83%</td>
            <td>0.17%</td>
            <td>120</td>
            <td>220</td>
            <td>91</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>222</td>
            <td>657</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>746</td>
            <td>568</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">8/18/2023</td>
            <td>Open Banking API</td>
            <td>98.70%</td>
            <td>1.30%</td>
            <td>373</td>
            <td>751</td>
            <td>943</td>
            <td>0.089%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>98.70%</td>
            <td>1.30%</td>
            <td>1338</td>
            <td>1569</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1026</td>
            <td>613</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>92</td>
            <td>178</td>
            <td>77</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>143</td>
            <td>471</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>436</td>
            <td>388</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/20/2023</td>
            <td>Open Banking API</td>
            <td>97.74%</td>
            <td>2.26%</td>
            <td>176</td>
            <td>334</td>
            <td>281</td>
            <td>0.028%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>176</td>
            <td>533</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>487</td>
            <td>439</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>182</td>
            <td>88</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>244</td>
            <td>752</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>5237</td>
            <td>624</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>104</td>
            <td>177</td>
            <td>83</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>216</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>738</td>
            <td>566</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>173</td>
            <td>79</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>208</td>
            <td>628</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>732</td>
            <td>562</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>94</td>
            <td>174</td>
            <td>78</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>609</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>734</td>
            <td>571</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>175</td>
            <td>81</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>644</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>762</td>
            <td>606</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>166</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>145</td>
            <td>462</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>434</td>
            <td>391</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>91</td>
            <td>171</td>
            <td>76</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>172</td>
            <td>486</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>482</td>
            <td>442</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>176</td>
            <td>83</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>245</td>
            <td>757</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>854</td>
            <td>651</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>173</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>215</td>
            <td>639</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>766</td>
            <td>560</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">11/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>177</td>
            <td>82</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>236</td>
            <td>670</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>805</td>
            <td>610</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/1/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>177</td>
            <td>82</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>243</td>
            <td>688</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>840</td>
            <td>629</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/2/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>173</td>
            <td>80</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>253</td>
            <td>725</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>749</td>
            <td>588</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/3/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>166</td>
            <td>72</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>146</td>
            <td>467</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>417</td>
            <td>365</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/4/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>171</td>
            <td>73</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>179</td>
            <td>476</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>489</td>
            <td>404</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/5/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>185</td>
            <td>87</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>274</td>
            <td>826</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>827</td>
            <td>656</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/6/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>95</td>
            <td>184</td>
            <td>82</td>
            <td>0.07%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>660</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>1278</td>
            <td>572</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/7/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>181</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>635</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>704</td>
            <td>553</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/8/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>179</td>
            <td>83</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>214</td>
            <td>651</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>708</td>
            <td>564</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/9/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>180</td>
            <td>85</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>229</td>
            <td>722</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>705</td>
            <td>549</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/10/2023</td>
            <td>Open Banking API</td>
            <td>89.00%</td>
            <td>11.00%</td>
            <td>81</td>
            <td>167</td>
            <td>73</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>89.00%</td>
            <td>11.00%</td>
            <td>139</td>
            <td>465</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>89.00%</td>
            <td>11.00%</td>
            <td>392</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/11/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>173</td>
            <td>76</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>483</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>456</td>
            <td>406</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/12/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>180</td>
            <td>84</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>233</td>
            <td>771</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>766</td>
            <td>565</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/13/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>229</td>
            <td>92</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>692</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>701</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/14/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>96</td>
            <td>175</td>
            <td>80</td>
            <td>0.008%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>646</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>702</td>
            <td>526</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/15/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>114</td>
            <td>82</td>
            <td>0.004%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>206</td>
            <td>608</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>746</td>
            <td>550</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/16/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>73</td>
            <td>133</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>204</td>
            <td>548</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>702</td>
            <td>520</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/17/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>86</td>
            <td>64</td>
            <td>75</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>141</td>
            <td>358</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>432</td>
            <td>373</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/18/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>93</td>
            <td>71</td>
            <td>79</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>150</td>
            <td>376</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>469</td>
            <td>401</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/19/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>67</td>
            <td>84</td>
            <td>0.03%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>220</td>
            <td>620</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>783</td>
            <td>559</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/20/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>66</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>205</td>
            <td>546</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>727</td>
            <td>530</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/21/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>97</td>
            <td>67</td>
            <td>82</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>196</td>
            <td>521</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>717</td>
            <td>519</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/22/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>69</td>
            <td>82</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>203</td>
            <td>540</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>739</td>
            <td>546</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/23/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>69</td>
            <td>81</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>199</td>
            <td>537</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>694</td>
            <td>538</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/24/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>89</td>
            <td>61</td>
            <td>69</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>131</td>
            <td>340</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>406</td>
            <td>366</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/25/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>100</td>
            <td>71</td>
            <td>75</td>
            <td>0.006%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>139</td>
            <td>335</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>411</td>
            <td>367</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/26/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>88</td>
            <td>63</td>
            <td>70</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>132</td>
            <td>334</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>421</td>
            <td>372</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/27/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>98</td>
            <td>67</td>
            <td>81</td>
            <td>0.002%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>210</td>
            <td>597</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>752</td>
            <td>542</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">9/28/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>101</td>
            <td>66</td>
            <td>80</td>
            <td>0.007%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>197</td>
            <td>513</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>730</td>
            <td>531</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/29/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>66</td>
            <td>82</td>
            <td>0.003%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>209</td>
            <td>553</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>765</td>
            <td>563</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>68</td>
            <td>83</td>
            <td>0.068%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>666</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>97.60%</td>
            <td>2.40%</td>
            <td>875</td>
            <td>618</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td rowspan="3" class="text-nowrap">12/30/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>99</td>
            <td>68</td>
            <td>83</td>
            <td>0.068%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>235</td>
            <td>666</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>97.60%</td>
            <td>2.40%</td>
            <td>875</td>
            <td>618</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr><tr>
            <td rowspan="3" class="text-nowrap">12/31/2023</td>
            <td>Open Banking API</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>90</td>
            <td>63</td>
            <td>71</td>
            <td>0.02%</td>
          </tr>
          <tr>
            <td>Mobile APP</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>140</td>
            <td>364</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          <tr>
            <td>Internet bank</td>
            <td>100.00%</td>
            <td>0.00%</td>
            <td>476</td>
            <td>410</td>
            <td>N/A</td>
            <td>N/A</td>
          </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</main>
<app-footer></app-footer>

