import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";

export const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const source = urlParams.get("utm_source") || "";
  const medium = urlParams.get("utm_medium") || "";
  const campaign = urlParams.get("utm_campaign") || "";

  return [source, medium, campaign].filter(Boolean).join("&");
};

@Component({
  selector: "app-complaints",
  templateUrl: "./complaints.component.html",
  styleUrls: ["./complaints.component.scss"],
})
export class ComplaintsComponent implements OnInit, OnDestroy {
  isSubmitted: boolean = false;
  isSuccess: boolean = false;
  mailForm: FormGroup;
  constructor(private router: Router) {}
  ngOnDestroy() {
    document.body.classList.remove("no-scroll");
  }

  @ViewChild("filesInput") filesInput;
  @ViewChild("dateInput") dateInput;

  isError(controlName: string) {
    if (!this.mailForm.get(controlName).touched) {
      return false;
    }

    return this.mailForm.get(controlName).invalid;
  }

  files: File[];

  complete() {
    this.isSuccess = false;
    this.isSubmitted = false;

    document.body.classList.remove("no-scroll");
  }
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    const validPattern = /^[+0-9()-\s]*$/;
    const firstCharPlus = value.startsWith("+");

    value = value
      .split("")
      .filter((char) => {
        if (!validPattern.test(char)) {
          return false;
        }

        return true;
      })
      .join("");

    value = value.replace(/[-]+/g, (match) => match.charAt(0));
    value = value.replace(/[(]+/g, (match) => match.charAt(0));
    value = value.replace(/[)]+/g, (match) => match.charAt(0));
    value = value.replace(/[\s]+/g, (match) => match.charAt(0));

    if (value.includes("+")) {
      value = value.replace(/\+/g, "");
      if (firstCharPlus) {
        value = `+${value}`;
      }
    }

    input.value = value;

    this.mailForm.get("mobilePhoneNumber").setValue(input.value);
  }

  onAttachmentsChange(event: Event) {
    const files = (event.target as HTMLInputElement).files;
    this.updateFileList(Array.from(files));
  }

  updateFileList(files: File[]) {
    this.files = undefined;
    this.mailForm.get("attachments").clearValidators();
    this.mailForm.get("attachments").markAsTouched();
    const size = Array.from(files).reduce((res, item) => {
      const fileSize = item.size / 1024 / 1024; // in MiB

      return fileSize + res;
    }, 0);
    if (size > 32) {
      this.mailForm.get("attachments").setErrors({ incorrect: true });

      return;
    }
    this.files = files;

    console.log(this.mailForm.get("attachments").value);
  }

  onRemoveFile(file: File) {
    const filesArray = Array.from(this.files);
    const nextFilesArray = filesArray.filter((item) => {
      return item !== file;
    });

    this.updateFileList(nextFilesArray);
  }

  onSelectFiles() {
    (<HTMLElement>window.document.activeElement)?.blur();
    (<any>this.filesInput)?.nativeElement.focus();
    (<any>this.filesInput)?.nativeElement.click();
  }

  onSelectDate() {
    (<HTMLElement>window.document.activeElement)?.blur();
    (<any>this.dateInput)?.nativeElement.focus();
    (<any>this.dateInput)?.nativeElement.showPicker();
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    this.mailForm = new FormGroup({
      name: new FormControl(null, {
        validators: [Validators.required],
      }),
      email: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.email,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ],
      }),
      mobilePhoneNumber: new FormControl(null, {
        validators: [
          Validators.required,
          Validators.pattern(/^[+0-9()-\s]+$/),
          Validators.maxLength(50),
        ],
      }),
      complaintSubject: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(100)],
      }),
      complaintSummary: new FormControl(null, {
        validators: [Validators.required, Validators.maxLength(200)],
      }),
      relevantDateOfComplaintEvent: new FormControl(undefined, {
        validators: [],
      }),
      attachments: new FormControl(null, {
        validators: [],
      }),
      agreement: new FormControl(null, {
        validators: [Validators.requiredTrue],
      }),
    });
  }

  getDate() {
    return this.mailForm.get("relevantDateOfComplaintEvent").value
      ? new Date(this.mailForm.get("relevantDateOfComplaintEvent").value)
          .toISOString()
          .split("T")[0]
      : null;
  }

  submit(event: Event) {
    this.isSubmitted = true;

    if (this.mailForm.status !== "VALID") {
      Object.values(this.mailForm.controls).forEach((control) => {
        control.markAsTouched();
      });
      return;
    }

    window.scrollTo(0, 0);

    const form_id = window.location.host.includes("papaya-dev") ? 3001 : 3000;

    const payload = {
      channel_name: getUTMParams(),
      date: new Date().toISOString().split("T")[0],
      form_id,
      form_name: "complaint",
      uuid: "",
      params: {
        name: this.mailForm.get("name").value,
        email: this.mailForm.get("email").value,
        mobilePhoneNumber: this.mailForm.get("mobilePhoneNumber").value,
        complaintSubject: this.mailForm.get("complaintSubject").value,
        complaintSummary: this.mailForm.get("complaintSummary").value,
        relevantDateOfComplaintEvent: this.getDate(),
      },
    };

    const formData = new FormData();
    formData.append(
      "review",
      new Blob([JSON.stringify(payload)], { type: "application/json" })
    );

    if (this.files) {
      for (let i = 0; i < this.files.length; i++) {
        formData.append("files", this.files[i], this.files[i].name);
      }
    }

    fetch("https://marketing.blackcatcard.com/reviews/v2", {
      method: "POST",
      body: formData,
    });

    this.files = undefined;

    this.mailForm.reset();

    this.isSuccess = true;
    document.body.classList.add("no-scroll");
  }
}
