import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { IntroComponent } from './intro/intro.component';
import { PartnersComponent } from './partners/partners.component';
import { ServicesComponent } from './services/services.component';
import { HeaderComponent } from './header/header.component';
import { AboutComponent } from './about/about.component';
import { ContactsComponent } from './contacts/contacts.component';
import { FaqComponent } from './faq/faq.component';
import { CareersComponent } from './careers/careers.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DebounceClickDirective } from './debounce-click.directive';
import { OnlineBankingComponent } from './online-banking/online-banking.component';
import { OpenBankingDocumentationComponent } from './open-banking-documentation/open-banking-documentation.component';
import { CookiePolicyComponent } from './cookie-policy/cookie-policy.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { FraudDisclaimerComponent } from './fraud-disclaimer/fraud-disclaimer.component';
import { CustomerSecurityComponent } from './customer-security/customer-security.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { OpenbankingTermsAndConditionsComponent } from './openbanking-terms-and-conditions/openbanking-terms-and-conditions.component';
import { GettingStartedGuideComponent } from './getting-started-guide/getting-started-guide.component';
import { EidasCertificateComponent } from './eidas-certificate/eidas-certificate.component';
import { ProductionEnvironmentComponent } from './production-environment/production-environment.component';
// tslint:disable-next-line:max-line-length
import { GeneralInformationAboutTheSandboxComponent } from './general-information-about-the-sandbox/general-information-about-the-sandbox.component';
import { AvailabilityComponent } from './availability/availability.component';
// tslint:disable-next-line:max-line-length
import { ScaStrongCustomerAuthenticationMethodsComponent } from './sca-strong-customer-authentication-methods/sca-strong-customer-authentication-methods.component';
import { SecurityComponent } from './security/security.component';
import { AboutApiComponent } from './about-api/about-api.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TtpComponent } from './ttp/ttp.component';
import { SuccessComponent } from './success/success.component';
import { TtpRegistrationService } from './services/ttpRegistration.service';
import { HttpClientModule } from '@angular/common/http';
import { CountryService } from './services/countryService.service';
import { HelperService } from './utils/helperService';
import { CurrencyRatesComponent } from './currency-rates/currency-rates.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { ComplaintsComponent } from './complaints/complaints.component';
import { StatisticQ12024Component } from './statistic-q12024/statistic-q12024.component';
import { StatisticQ22024Component } from './statistic-q22024/statistic-q22024.component';
import { StatisticQ32024Component } from './statistic-q32024/statistic-q32024.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    IntroComponent,
    PartnersComponent,
    ServicesComponent,
    HeaderComponent,
    AboutComponent,
    ContactsComponent,
    ComplaintsComponent,
    FaqComponent,
    CareersComponent,
    DebounceClickDirective,
    OnlineBankingComponent,
    OpenBankingDocumentationComponent,
    CookiePolicyComponent,
    PrivacyPolicyComponent,
    FraudDisclaimerComponent,
    CustomerSecurityComponent,
    TermsConditionsComponent,
    OpenbankingTermsAndConditionsComponent,
    GettingStartedGuideComponent,
    EidasCertificateComponent,
    ProductionEnvironmentComponent,
    GeneralInformationAboutTheSandboxComponent,
    AvailabilityComponent,
    ScaStrongCustomerAuthenticationMethodsComponent,
    SecurityComponent,
    AboutApiComponent,
    TtpComponent,
    SuccessComponent,
    CurrencyRatesComponent,
    StatisticsComponent,
    StatisticQ12024Component,
    StatisticQ22024Component,
    StatisticQ32024Component,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
  ],
  providers: [TtpRegistrationService, CountryService, HelperService],
  bootstrap: [AppComponent],
})
export class AppModule {}
